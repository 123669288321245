import { render, staticRenderFns } from "./DistributionAllocationResult.vue?vue&type=template&id=880fb154&scoped=true"
import script from "./DistributionAllocationResult.vue?vue&type=script&lang=js"
export * from "./DistributionAllocationResult.vue?vue&type=script&lang=js"
import style0 from "./DistributionAllocationResult.vue?vue&type=style&index=0&id=880fb154&prod&lang=scss"
import style1 from "./DistributionAllocationResult.vue?vue&type=style&index=1&id=880fb154&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "880fb154",
  null
  
)

export default component.exports