<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionCompareScheduleAchievements" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchParam.referenceDate"
                class="txt-single date-style"
                :label="$t('label.lbl_refernceDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="searchParam.referenceDateCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="searchParam.suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="getSupplierName"
                :rules="[rules.download]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="searchParam.toSid"
                item-value="value"
                item-text="text"
                :items="shippingList"
                :label="$t('label.lbl_shippingTo')"
                :hint="shipperName"
                class="txt-singles"
                persistent-hint
                :disabled="
                  searchParam.suppliersSelected == null || searchParam.suppliersSelected == ''
                "
                :search-input.sync="shipperInputText"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 商品コード/品名-->
            <div class="search-autocomplete">
              <v-autocomplete
                v-model="searchParam.itemStandardSid"
                item-value="value"
                item-text="text"
                :items="productList"
                :hint="productName"
                persistent-hint
                :search-input.sync="itemCdInputText"
                dense
                :disabled="
                  searchParam.suppliersSelected == null || searchParam.suppliersSelected == ''
                "
                :label="$t('label.lbl_productCnCd')"
              >
                <option disabled value="itemStandardSid"></option>
                <!-- アイテム一覧の表示  -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getList()">{{ $t("btn.btn_search") }}</v-btn>
              </div>

              <!--CSV出力ボタン領域-->
              <div class="btn-search-area" style="float: right">
                <!--CSV出力ボタン-->
                <v-btn class="other-btn-nosize btn-size" @click="download()">{{
                  $t("btn.btn_process_progress_csv_output")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :items-per-page="-1"
          :hide-default-footer="true"
          disable-pagination
          disable-filtering
          disable-sort
          height="620px"
          class="table-style"
        >
          <!-- 取引先名 -->
          <template v-slot:[`item.clientName`]="{ item }">
            <div>{{ item.clientName }}</div>
          </template>
          <!-- 予定：実績文言表示 -->
          <template v-slot:[`item.diffName`]>
            <div>予定</div>
            <div>実績</div>
          </template>

          <!-- 各工程 -->
          <template v-for="proc in procDataList" v-slot:[`item.plan_${proc}`]="{ item }">
            <template v-if="item['plan_' + proc] - item['actual_' + proc] == 0">
              <div :key="proc">
                <a @click="pageTransition(item, proc)">
                  <div>{{ item["plan_" + proc] }}</div>
                  <div>{{ item["actual_" + proc] }}</div>
                </a>
              </div>
            </template>
            <template v-else>
              <div class="diff2" :key="proc">
                <a @click="pageTransition(item, proc)">
                  <div>{{ item["plan_" + proc] }}</div>
                  <div>{{ item["actual_" + proc] }}</div>
                </a>
              </div>
            </template>
          </template>
        </v-data-table>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <!-- 進捗情報詳細モーダル-->
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  name: appConfig.SCREEN_ID.P_TMP_910,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ヘッダ
    // 検索ヘッダー
    searchParam: {
      suppliersSelected: "",
      referenceDate: "",
      referenceDateCal: "",
      toSid: "",
      itemStandardSid: "",
    },
    // カレンダー
    dateMenu: false,

    // 取引先
    supplierList: [],

    // 出荷先
    shippingList: [],
    shipperInputText: "",

    // 品番
    itemCdInputText: "",
    productList: [],

    // 一覧表示用
    totalCount: 0,
    headerItems: [],
    inputList: [],
    headerRespose: [],

    // バリデーション
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      download: () => true,
    },

    // その他ダイアログ
    openMenu: false,
    loadingCounter: 0,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
  }),
  methods: {
    /** 初期化処理 */
    init() {
      if (this.$route.params.searchParam) {
        this.searchParam = this.$route.params.searchParam;
        this.getSupplier();
        this.getList();
        return;
      }
      this.searchParam.suppliersSelected = "";
      this.searchParam.referenceDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getSupplier();
      this.getList(true);
    },

    // ヘッダー関連
    // カレンダー関連
    /** 実績日付をー１日 */
    preDateOfAchieveTo() {
      if (this.searchParam.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.searchParam.referenceDateCal);
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /** 実績日付を+１日 */
    nextDateOfAchieveTo() {
      if (this.searchParam.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.searchParam.referenceDateCal);
      this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /** 日付入力 */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(4, 6) + "/" + inputVal.slice(6, 8);
      } else {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(5, 7) + "/" + inputVal.slice(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.searchParam.referenceDateCal = dateTimeHelper.dateCalc(strDt);
        this.searchParam.referenceDate = strDt;
      } else {
        this.searchParam.referenceDateCal = null;
      }
    },

    /** カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。*/
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /** 表示用データ取得 */
    getList(headerOnlyFlg = false) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      this.deleteRules();

      // API接続用のconfigを取得
      const config = this.$httpClient.createGetApiRequestConfig();

      // パラメータ設定
      this.setParam(config, headerOnlyFlg);

      // 各工程の予定、実績を取得
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_PLAN_ACTUAL_DIFF, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              // ヘッダー作成用
              this.createHeader(jsonData.resIdv.procCdList);

              // データテーブル用
              if (jsonData.resIdv.planActualList.length == 0) {
                this.totalCount = 0;
                this.inputList = [];
                return;
              }

              this.inputList = jsonData.resIdv.planActualList.map((row) => {
                let item = {};
                item.clientSid = row.clientSid;
                item.clientName = row.clientName;
                // 各取引先行毎にpaln_**、actual_**で値を展開してお
                let procAndActualList = {};
                row.clientPlanActualList.forEach((procData) => {
                  procAndActualList["plan_" + procData.procCd] = procData.planStock;
                  procAndActualList["actual_" + procData.procCd] = procData.actualStock;
                });

                item = {
                  ...item,
                  ...procAndActualList,
                };
                return item;
              });
              this.totalCount = this.inputList.length;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getList() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    setParam(config, headerOnlyFlg = false) {
      if (headerOnlyFlg) {
        config.params.headerOnlyFlg = headerOnlyFlg; // ヘッダーのみ取得
      }
      config.params.yyyymmddHhmmss = dateTimeHelper.convertUTC(this.searchParam.referenceDate); // 基準日
      if (this.searchParam.suppliersSelected) {
        config.params.clientSid = this.searchParam.suppliersSelected; // 取引先
      }
      if (this.searchParam.toSid) {
        config.params.toSid = this.searchParam.toSid; // 出荷先
      }
      if (this.searchParam.itemStandardSid) {
        config.params.itemStandardSid = this.searchParam.itemStandardSid; // 品番
      }
    },

    /** データテーブル関連 */
    createHeader(headerRespose) {
      this.headerItems = [
        {
          text: i18n.tc("label.lbl_supplier"),
          value: "clientName",
          width: "346px",
          align: "left",
          class: "diff2",
          sortable: true,
        },
        { text: "", value: "diffName", width: "148px", align: "right", sortable: false }, //予定、実績の文言を表示するため
      ];
      this.headerRespose = headerRespose;
      headerRespose.forEach((proc) => {
        this.headerItems.push({
          text: proc.procNameShort,
          value: "plan_" + proc.procCd,
          width: "100px",
          align: "right",
          class: "diff2",
          sortable: false,
        });
      });
    },

    /** 工程別実績CSV出力 */
    download() {
      // バリデーション処理
      this.addRules();

      // バリデーションルール追加後、ＤＯＭの更新を待ってからダウンロード処理の実行
      this.$nextTick(() => {
        const isValid = this.$refs.form.validate();
        if (!isValid) {
          return;
        }

        // ローディング画面表示ON
        this.loadingCounter = 1;

        // パラメータ設定
        const body = this.setPostParam();

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.DST_PLAN_ACTUAL_DIFF_CSV_DOWNLOAD, body)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              if (jsonData.resCom.resComCode == "000") {
                window.location.href = jsonData.resIdv.downloadUrl;
              } else {
                // エラーメッセージをpopupのmessageに格納
                this.infoDialog.message = jsonData.resCom.resComMessageId
                  ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                  : jsonData.resCom.resComMessages[
                      "resComMessage" + sessionStorage.getItem("lang")
                    ];
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve(response);
            })
            .catch((resolve) => {
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      });
    },

    /** ルール追加 */
    addRules() {
      this.$set(this.rules, "download", (value) => !!value || "取引先を入力してください");
    },

    /** ルール削除 */
    deleteRules() {
      this.$delete(this.rules, "download");
    },

    /** Postパラメータセット */
    setPostParam() {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TMP_910;

      body.reqIdv.yyyymmddHhmmss = dateTimeHelper.convertUTC(this.searchParam.referenceDate); // 基準日
      if (this.searchParam.suppliersSelected) {
        body.reqIdv.clientSid = this.searchParam.suppliersSelected; // 取引先
      } else {
        return;
      }

      if (this.searchParam.toSid) {
        body.reqIdv.toSid = this.searchParam.toSid; // 出荷先
      }
      if (this.searchParam.itemStandardSid) {
        body.reqIdv.itemStandardSid = this.searchParam.itemStandardSid; // 品番
      }

      return body;
    },

    /** 取引先API接続 */
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  compSid: row.compSid,
                });
              });
              this.supplierList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /** 加工計画進捗詳細ページ遷移 */
    pageTransition(item, proc) {
      // 入力チェック
      if (this.$refs.form.validate()) {
        // 遷移する
        // 修正画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_911,
          params: {
            // 検索条件
            searchParam: this.searchParam,
            // クリックした行
            clickItem: item,
            // クリックした工程コード
            procCd: proc,
            // クリックした工程名
            procName: this.headerRespose.find((v) => v.procCd == proc).procNameShort,
          },
        });
      }
    },
  },

  watch: {
    "searchParam.referenceDateCal": function (val) {
      this.searchParam.referenceDate = this.formatDate(val);
    },
    "searchParam.suppliersSelected": function (newVal) {
      if (!newVal) {
        this.searchParam.toSid = "";
        this.searchParam.itemStandardSid = "";
      }
    },
    /**
     * 出荷先取得
     */
    shipperInputText(val) {
      if (val ?? false) {
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }

        if (
          this.searchParam.suppliersSelected == null ||
          this.searchParam.suppliersSelected == ""
        ) {
          this.shippingList = [];
          this.searchParam.toSid = "";
          return;
        }

        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        let shipper = this.shippingList.find((v) => v.value == this.searchParam.toSid);
        if (shipper != null && val == shipper.text) {
          return;
        }

        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            this.supplierList.find((v) => v.value == this.searchParam.suppliersSelected).compSid,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },

    /**
     * 品番入力
     */
    itemCdInputText(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (
          this.searchParam.suppliersSelected == null ||
          this.searchParam.suppliersSelected == ""
        ) {
          return;
        }
        // 表示内容が選択した内容と一致している場合は、確定した内容なので変更しない
        let product = this.productList.find((v) => v.value == this.searchParam.itemStandardSid);
        if (product != null && val == product.text) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.searchParam.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.productList = list.concat(result[0]);
              this.productList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.productList.length = 0;
        }
      }
    },
  },
  computed: {
    // 取引先名
    getSupplierName() {
      let supplier = this.supplierList.find((v) => v.value === this.searchParam.suppliersSelected);
      if (supplier) {
        return supplier.name;
      }
      return "";
    },
    // 出荷先
    shipperName() {
      if (this.searchParam.suppliersSelected == null || this.searchParam.suppliersSelected == "") {
        return "取引先が選択されていません";
      }

      let shipper = this.shippingList.find((v) => v.value === this.searchParam.toSid);
      if (shipper) {
        return shipper.name;
      }
      return "";
    },
    // 品番
    productName() {
      if (this.searchParam.suppliersSelected == null || this.searchParam.suppliersSelected == "") {
        return "取引先が選択されていません";
      }

      let prodct = this.productList.find((v) => v.value === this.searchParam.itemStandardSid);
      if (prodct) {
        return prodct.name;
      }
      return "";
    },
    /** 各工程の工程コードを動的に変更するための工程リストを取得 */
    procDataList() {
      let keys = this.inputList.flatMap((v) => Object.keys(v));
      let keySet = new Set(keys);
      return [...keySet].filter((v) => v.startsWith("plan_")).map((v) => v.replace("plan_", ""));
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}

.table-style {
  overflow-x: auto;
}

.proc-width {
  width: 100px;
}

.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
.diff {
  background-color: #ffc0cb;
  // background-color:#d09cfc; 出荷保障の色
}
.diff2 {
  background-color: #d09cfc;
  // background-color:#d09cfc; 出荷保障の色
}
.marginRightNone {
  margin-right: 0px;
}

.btn-size {
  width: 9rem;
}
</style>
