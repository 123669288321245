<!-- 加工計画一覧画面-->
<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionProcessingPlanSearchList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete-supplier first-search-item" style="width: 350px">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.supplierRequired]"
                pa-3
                :error-messages="alertSupplierMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 計画区分 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="planningSection"
                :items="planningSectionList"
                :label="$t('label.lbl_planningSection')"
                :hint="setPlanningSection()"
                class="txt-single"
                persistent-hint
                @change="changePlanningSection"
                :rules="[rules.inputRequired]"
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作業日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrProcessingDateFrom"
                class="txt-single date-style"
                :label="processingDateLabel + '(From)'"
                @change="changeProcessingDateFrom"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrProcessingDateFromCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday-ProcessingDateFrom"
                class="day-ctrl-btn"
                @click="preDateOfProcessingFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow-ProcessingDateFrom"
                class="day-ctrl-btn"
                @click="nextDateOfProcessingFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- toカレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrProcessingDateTo"
                class="txt-single date-style"
                :label="processingDateLabel + '(To)'"
                @change="changeProcessingDateTo"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrProcessingDateToCal" @input="dateMenu2 = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday-ProcessingDateTo"
                class="day-ctrl-btn"
                @click="preDateOfProcessingTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow-ProcessingDateTo"
                class="day-ctrl-btn"
                @click="nextDateOfProcessingTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 伝票No -->
            <div>
              <v-text-field
                outlined
                dense
                v-model="voucherNo"
                class="search-textbox-voucherNo"
                :label="$t('label.lbl_voucherNo')"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <v-row class="search-row">
            <!-- 資材引当結果 -->

            <div class="search-autocomplete-supplier first-search-item" style="width: 350px">
              <v-autocomplete
                dense
                v-model="allocationResult"
                :items="allocationResultList"
                :label="$t('label.lbl_materialsAllocationResult')"
                :hint="setAllocationResult()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="require">&nbsp;</span>
            <span class="item-spacer">&nbsp;</span>

            <!-- 作成日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrCreatedDateFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_createDate')"
                @change="changeCreatedDateFrom"
                :rules="[rules.yyyymmddAndBlank]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">&nbsp;</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu3"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrCreatedDateFromCal" @input="dateMenu3 = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday-CreatedDateFrom"
                class="day-ctrl-btn"
                @click="preDateOfCreatedFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow-CreatedDateFrom"
                class="day-ctrl-btn"
                @click="nextDateOfCreatedFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getApiList()">{{ $t("btn.btn_search") }}</v-btn>
                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="clearConfirm">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!-- データ出力ボタン -->
                <v-btn color="primary" id="btn-search" class="other-btn" @click="download">
                  {{ $t("btn.btn_outPutData") }}</v-btn
                >

                <!-- 指示作成ボタン -->
                <v-btn
                  color="primary"
                  id="btn-search"
                  class="other-btn"
                  @click="instructionsCreate"
                >
                  {{ $t("btn.btn_reservedInstCreate") }}</v-btn
                >

                <!-- 指示取消ボタン -->
                <v-btn
                  color="primary"
                  id="btn-search"
                  class="other-btn"
                  @click="instructionsCancel"
                >
                  {{ $t("btn.btn_reservedInstCancel") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
        >
          <!-- ヘッダーチェックボックス-->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="selectCheck(index)"
              style="transform: scale(2)"
            />
          </template>
          <!-- 作業日 -->
          <template v-slot:[`item.processingDate`]="{ item }">
            <div class="listNo-style" align="left">{{ item.processingDate }}</div>
          </template>

          <!-- 伝票No(作業No) -->
          <template v-slot:[`item.processingNo`]="{ item }">
            <div class="listNo-style" align="left">{{ item.processingNo }}</div>
          </template>

          <!-- 有償/無償 -->
          <template v-slot:[`item.isPaid`]="{ item }">
            <div class="listNo-style" align="left">{{ item.isPaid }}</div>
          </template>

          <!-- 備考欄 -->
          <template v-slot:[`item.remarksTextbox`]="{ item }">
            <div class="listNo-style" align="left">{{ item.remarksTextbox }}</div>
          </template>

          <!-- 計画区分 -->
          <template v-slot:[`item.planningSection`]="{ item }">
            <div class="listNo-style" align="left">{{ item.planningSection }}</div>
          </template>

          <!-- 作成日時 -->
          <template v-slot:[`item.createdDate`]="{ item }">
            <div v-for="(datetime, index) in item.createdDate.split(' ')" :key="index">
              <div class="listNo-style" align="center">{{ datetime }}</div>
            </div>
          </template>

          <!-- 引当結果 -->
          <template v-slot:[`item.allocationResult`]="{ item }">
            <a
              v-if="item.allocationResult != '02'"
              class="unregistered-sing"
              align="left"
              style="text-decoration: underline"
              @click="toAllocationResult(item)"
              >{{ item.allotmentResultName }}</a
            >
            <a
              v-if="item.allocationResult == '02'"
              class="finish-sing"
              align="left"
              style="text-decoration: underline"
              @click="toAllocationResult(item)"
              >{{ item.allotmentResultName }}</a
            >
          </template>

          <!-- 計画詳細ボタン -->
          <template v-slot:[`item.planDetail`]="{ item }">
            <!-- <v-btn small @click="openUpdateDelete(item)" text> -->
            <v-btn small @click="toUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(inputList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="clear"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />

      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ message }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_TMP_903,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    loadingCounter: 0,
    selected: [],
    // 総件数
    totalCount: 0,
    // 警告ダイアログ
    alertDialog: false,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      isOpen: false,
      redMessage: "",
    },
    //遷移フラグ
    transitionFlg: false,
    // 初期データ
    defaultData: [],
    // 伝票No
    voucherNo: "",
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    //チェック行インデックス
    checkIndex: -1,
    // 取引先名
    clientNm: "",
    // 有償/無償
    isPaid: "",
    // 取引先
    suppliersSelected: "",
    // 引当結果
    allocationResult: "",
    // 引当結果コード
    allocationResultCd: "",
    // 計画区分
    planningSection: "",
    //計画区分コード
    planningSectionCd: "",
    // 処理区分
    arrivalStatusSelected: "",
    // メニュー
    openMenu: null,
    // 作業日
    arrProcessingDateFrom: "",
    arrProcessingDateFromCal: "",
    // 作業日to
    arrProcessingDateTo: "",
    arrProcessingDateToCal: "",
    // 日付ラベル
    processingDateLabel: "",
    // 日付ラベルリスト
    dateLabelList: [],

    // 作成日
    arrCreatedDateFrom: "",
    arrCreatedDateFromCal: "",

    // 初期化フラグ
    initFlag: true,

    // 加工計画日付カレンダー
    dateMenu: false,
    dateMenu2: false,
    dateMenu3: false,
    dateMenu4: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // アラートメッセージ
    alertMessage: "",
    // 取引先アラート
    alertSupplierMessage: "",
    message: "",
    // 実績登録権限
    achieveRole: false,
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        text: i18n.tc("lbl_select"),
        value: "check",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 計画日 or 入荷予定日 or 出荷予定日
      {
        text: i18n.tc("label.lbl_planDay"),
        value: "processingDate",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 計画区分
      {
        text: i18n.tc("label.lbl_planningSection"),
        value: "planningSection",
        align: "left",
        width: "7%",
        sortable: true,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_voucherNo"),
        value: "voucherNo",
        width: "12%",
        align: "left",
        sortable: true,
      },
      // 資材引当結果
      {
        text: i18n.tc("label.lbl_materialsAllocationResult"),
        value: "allocationResult",
        align: "left",
        width: "6%",
        sortable: false,
      },
      // 有償/無償
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "isPaid",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 作成日時
      {
        text: i18n.tc("label.lbl_noticeDate"),
        value: "createdDate",
        align: "center",
        width: "5%",
        sortable: false,
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarksTextbox"),
        value: "remarksTextbox",
        align: "left",
        width: "10%",
        sortable: true,
      },
      // 出力済み
      {
        text: i18n.tc("label.lbl_already_output"),
        value: "printFlg",
        align: "left",
        width: "5%",
        sortable: true,
      },
      // 計画詳細
      {
        text: i18n.tc("label.lbl_planDetail"),
        value: "planDetail",
        align: "center",
        width: "4%",
        sortable: false,
      },
    ],
    //テーブルデータリスト
    inputList: [],
    //取引先リスト
    supplierList: [],
    //資材引当結果リスト
    allocationResultList: [],
    //計画区分リスト
    planningSectionList: [],
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      yyyymmddAndBlank: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        !value ||
        i18n.tc("check.chk_inputDate"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },
    //検索条件
    searchParam: {
      searchSuppliersSelected: "", // 取引先
      searchVoucherNo: "", // 伝票No
      searcharrProcessingDateFrom: "", // 作業日(FROM)
      searcharrProcessingDateFromCal: "", // 作業日(FROM)カレンダー
      searcharrProcessingDateTo: "", // 作業日(TO)
      searcharrProcessingDateToCal: "", // 作業日(TO)カレンダー
      searchPlanningSection: "", //計画区分
      searchAllocationResult: "", // 資材引当結果
      searcharrCreatedDateFrom: "", // 作成日
      searcharrCreatedDateFromCal: "", // 作成日カレンダー
    },
  }),

  methods: {
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.showList();
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.showList();
    },
    //計画区分変更
    changePlanningSection() {
      // ヘッダの計画日を変更
      this.processingDateLabel = this.getDateLabel();
      this.headerItems.splice(
        1,
        1,
        // 計画日
        {
          text: this.processingDateLabel,
          value: "processingDate",
          width: "7%",
          align: "left",
          sortable: true,
        }
      );
    },

    // 指示作成ボタン押下時
    instructionsCreate() {
      // チェックした行
      const checkList = this.inputList.filter((data) => data.check);
      //伝票SIDリスト
      const slipSidList = [];

      // １つもチェックされていない場合、画面遷移できない
      if (!checkList.length) {
        // なければ警告ダイアログ表示
        this.alertDialog = true;
        this.message = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }

      // 資材引当結果が引当済のデータを選択した場合、画面遷移できない
      for (let i = 0; i < checkList.length; i++) {
        // 引当済のデータを選択時、警告ダイアログを表示
        if (checkList[i].allocationResult == "02") {
          this.alertDialog = true;
          this.message = messsageUtil.getMessage("P-TMP-903_001_E");
          return;
        }
        slipSidList.push(checkList[i].shipSlipSid);
      }
      //入力チェック
      if (this.$refs.form.validate()) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_906,
          params: {
            distributionInstructionsCreationList: {
              // 検索条件
              headerList: this.searchParam,
              // 取引先名
              suppliersSelectedName: this.supplierList.find(
                (data) => data.value === this.suppliersSelected
              ).text,
              // 伝票SID
              inOutListSub: slipSidList,
              // 作業日ラベル
              processingDateLabel: this.processingDateLabel,
              // 遷移元
              screenName: appConfig.SCREEN_ID.P_TMP_903,
            },
          },
        });
      } else {
        //遷移しない
        return;
      }
    },

    // 指示取消ボタン押下時
    instructionsCancel() {
      // チェックした行
      const checkList = this.inputList.filter((data) => data.check);
      // 伝票SIDリスト
      const slipSidList = [];

      // １つもチェックされていない場合、画面遷移できない
      if (!checkList.length) {
        // なければ警告ダイアログ表示
        this.alertDialog = true;
        this.message = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }

      // 資材引当結果が引当済以外のデータを選択した場合、画面遷移できない
      for (let i = 0; i < checkList.length; i++) {
        if (checkList[i].allocationResult == "01") {
          // 引当済以外のデータ選択時、警告ダイアログを表示
          this.alertDialog = true;
          this.message = messsageUtil.getMessage("P-TMP-903_002_E");
          return;
        }
        slipSidList.push(checkList[i].shipSlipSid);
      }
      //入力チェック
      if (this.$refs.form.validate()) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_909,
          params: {
            distributionInstructionsCancelList: {
              // 検索条件
              headerList: this.searchParam,
              // 取引先名
              suppliersSelectedName: this.supplierList.find(
                (data) => data.value === this.suppliersSelected
              ).text,
              // 伝票SID
              inOutListSub: slipSidList,
              // 日付ラベル
              processingDateLabel: this.processingDateLabel,
              // 遷移元
              screenName: appConfig.SCREEN_ID.P_TMP_903,
            },
          },
        });
      } else {
        //遷移しない
        return;
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    //クリアボタン押下時
    clearConfirm() {
      // 確認ダイアログを表示
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    //クリア処理
    clear() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.$route.params.backPage || this.$route.params.from) {
        this.suppliersSelected = "";
        this.arrivalStatusSelected = "";
        this.arrCreatedDateFrom = "";
        this.arrCreatedDateFromCal = "";
        this.planningSection = "";
        this.voucherNo = "";
        this.allocationResult = "";
        this.$route.params.backPage = "";
        this.$route.params.from = "";
        // 遷移フラグ
        this.transitionFlg = false;
      }
      // 変更検知用の設定
      this.arrProcessingDateFromCal = "";
      this.arrProcessingDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    // データ出力ボタン押下時の処理
    download() {
      // チェックした行
      const checkList = this.inputList.filter((data) => data.check);
      // １つもチェックされていない場合、画面遷移できない
      if (!checkList.length) {
        // なければ警告ダイアログ表示
        this.alertDialog = true;
        this.message = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }

      // 出力済みが「不可」のものが含まれている場合は、CSV出力を行わない
      for (let i = 0; i < checkList.length; i++) {
        if (checkList[i].printFlg == this.printList[2].text) {
          //「不可」のものが含まれている場合は警告ダイアログ表示
          this.alertDialog = true;
          this.message = messsageUtil.getMessage("P-TMP-903_005_E");
          return;
        }
      }

      // チェックした行の伝票SIDリスト
      const slipSidList = checkList.map((data) => data.shipSlipSid);

      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      // 取引先存在チェック
      if (this.isEmpty(this.suppliersSelected)) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }

      // 作業日入力チェック
      if (
        new Date(this.arrProcessingDateFrom).getTime() >
        new Date(this.arrProcessingDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        return;
      }

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するためこのようなコードとなっている。
       */
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      if (this.$route.params.backPage || this.$route.params.from) {
        // 他画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      // バリデーションのチェックの結果がNGだった場合処理から抜ける
      if (!inputCheckResult) {
        return;
      }

      // ダウンロードAPI実行
      this.downloadApi(slipSidList);
    },

    // ダウンロードAPI実行
    downloadApi(slipSidList) {
      // 全選択を初期化
      this.isAllSelected = false;

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STC_003;
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 計画区分
      body.reqIdv.processingDiv = this.planningSection;
      // 伝票SID
      body.reqIdv.listSidList = slipSidList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DSTSCHEDULELIST_DOWNLOAD, body, appConfig.APP_CONFIG)
          .then((response) => {
            this.inputList.splice(0);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // ダウンロード開始
              window.location.href = jsonData.resIdv.downloadUrl;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.getApiList();
          });
      });
    },

    saveSearchParam() {
      //検索条件の保持
      this.searchParam.searchSuppliersSelected = this.suppliersSelected;
      this.searchParam.searchVoucherNo = this.voucherNo;
      this.searchParam.searcharrProcessingDateFrom = this.arrProcessingDateFrom;
      this.searchParam.searcharrProcessingDateFromCal = this.arrProcessingDateFromCal;
      this.searchParam.searcharrProcessingDateTo = this.arrProcessingDateTo;
      this.searchParam.searcharrProcessingDateToCal = this.arrProcessingDateToCal;
      this.searchParam.searchPlanningSection = this.planningSection;
      this.searchParam.searchAllocationResult = this.allocationResult;
      this.searchParam.searcharrCreatedDateFrom = this.arrCreatedDateFrom;
      this.searchParam.searcharrCreatedDateFromCal = this.arrCreatedDateFromCal;
    },

    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.suppliersSelected) {
        this.transitionFlg = true;
      } else {
        // 取引先
        this.suppliersSelected = "";
        // 伝票No
        this.voucherNo = "";
        // 計画区分
        this.planningSection = "";
        // テーブルデータ
        this.inputList = [];
        // 作業日(FROM)
        this.arrProcessingDateFrom = dateTimeHelper.convertJST();
        this.arrProcessingDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        // 作業日(TO)
        this.arrProcessingDateTo = dateTimeHelper.convertJST();
        this.arrProcessingDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      // コードマスタAPI実行メソッド
      this.getMstCode();
      // 取引先取得API実行メソッド
      this.getSupplier();
      // 計画区分と日付項目のマッピング取得
      this.dateLabelList = this.getProcessingDateLabel();
      // 日付ラベル取得
      this.processingDateLabel = this.getDateLabel();

      // 実績登録権限を取得
      this.achieveRole = sessionStorage.getItem(appConfig.SCREEN_ID.P_TMP_905);

      // 加工完成指示作成・加工完成指示取消・加工引当結果確認・加工計画修正・削除・入荷予定修正・削・出荷予定修正・削除から遷移
      if (this.$route.params.backPage || this.$route.params.from) {
        // ヘッダー取得
        this.getHeaderList(this.$route.params.headerList);
        // 検索API実行
        this.getApiList();
      }
    },
    /**
     * 遷移先から戻る際にヘッダー取得
     */
    getHeaderList(val) {
      // 取引先
      this.suppliersSelected = val.searchSuppliersSelected;
      // 作業日(FROM)
      this.arrProcessingDateFrom = val.searcharrProcessingDateFrom;
      this.arrProcessingDateFromCal = dateTimeHelper.dateCalc(val.searcharrProcessingDateFrom);
      // 作業日(TO)
      this.arrProcessingDateTo = val.searcharrProcessingDateTo;
      this.arrProcessingDateToCal = dateTimeHelper.dateCalc(val.searcharrProcessingDateTo);
      // 計画区分
      this.planningSection = val.searchPlanningSection;
      // 伝票No
      this.voucherNo = val.searchVoucherNo;
      // 指示作成状況モーダルから遷移時
      if (this.$route.params.from) {
        // 資材引当結果
        this.allocationResult = this.$route.params.allocationResultCd;
      } else {
        // 資材引当結果
        this.allocationResult = val.searchAllocationResult;
      }
      // 作成日
      this.arrCreatedDateFrom = val.searcharrCreatedDateFrom;
      if (val.searcharrCreatedDateFrom) {
        this.arrCreatedDateFromCal = dateTimeHelper.dateCalc(val.searcharrCreatedDateFrom);
      }
    },

    /**
     * 計画区分と日付項目のマッピング取得
     */
    getProcessingDateLabel() {
      return [
        {
          planningSection: "",
          label: i18n.tc("label.lbl_planDay"),
        },
        {
          planningSection: "01",
          label: i18n.tc("label.lbl_shippingScheduleDate"),
        },
        {
          planningSection: "02",
          label: i18n.tc("label.lbl_arrivalScheduleDate"),
        },
        {
          planningSection: "03",
          label: i18n.tc("label.lbl_planDay"),
        },
      ];
    },

    /**
     * 日付ラベル算出
     */
    getDateLabel() {
      return this.dateLabelList.find((section) => section.planningSection == this.planningSection)
        .label;
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 入荷予定日(作業日)カレンダー
    preDateOfProcessingFrom() {
      this.arrProcessingDateFromCal = this.moveDate(this.arrProcessingDateFromCal, -1);
    },
    nextDateOfProcessingFrom() {
      this.arrProcessingDateFromCal = this.moveDate(this.arrProcessingDateFromCal, 1);
    },

    // 作業日付カレンダーto
    preDateOfProcessingTo() {
      this.arrProcessingDateToCal = this.moveDate(this.arrProcessingDateToCal, -1);
    },
    nextDateOfProcessingTo() {
      this.arrProcessingDateToCal = this.moveDate(this.arrProcessingDateToCal, 1);
    },

    // 作成日カレンダー
    preDateOfCreatedFrom() {
      this.arrCreatedDateFromCal = this.moveDate(this.arrCreatedDateFromCal, -1);
    },
    nextDateOfCreatedFrom() {
      this.arrCreatedDateFromCal = this.moveDate(this.arrCreatedDateFromCal, 1);
    },
    // 日付移動ボタン前後処理
    moveDate(date, day) {
      if (!date) {
        return "";
      }
      let dateToMove = new Date(date);
      return dateTimeHelper.dateCalc(dateToMove.setDate(dateToMove.getDate() + day));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 入出荷予定日(作業日)
    changeProcessingDateFrom(val) {
      this.arrProcessingDateFromCal = this.formatInputDate(val);
    },
    // 作業日To
    changeProcessingDateTo(val) {
      this.arrProcessingDateToCal = this.formatInputDate(val);
    },
    // 作成日
    changeCreatedDateFrom(val) {
      this.arrCreatedDateFromCal = this.formatInputDate(val);
    },
    // 手動で入力された場合の日付フォーマット
    formatInputDate(date) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(date);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        return dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      } else {
        return "";
      }
    },

    /**
     * 流通加工計画修正・削除画面遷移
     */
    toUpdateDelete(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      // 入力チェック
      if (this.$refs.form.validate()) {
        // 遷移する
        // 計画区分が"加工"の場合
        if (this.inputList[this.editedIndex].planningSection == "加工") {
          // 修正画面へ渡す値を設定します。
          this.$router.push({
            name: appConfig.SCREEN_ID.P_TMP_904,
            params: {
              detailsList: {
                // 検索条件
                headerList: this.searchParam,
                // 取引先名
                suppliersSelectedName: this.supplierList.find(
                  (data) => data.value === this.suppliersSelected
                ).text,
                // 取引先リスト
                supplierList: this.supplierList,
                // 営業所SID
                officeCd: this.supplierList.find((data) => data.value === this.suppliersSelected)
                  .clientSid,
                // 伝票SID
                inOutListSub: this.inputList[this.editedIndex].shipSlipSid,
                // 作業日
                inOutScheduleDate: this.inputList[this.editedIndex].processingDate,
                // 伝票No
                inOutListNo: this.inputList[this.editedIndex].voucherNo,
                // 品質区分
                arrivalStatusSelected: this.inputList[this.editedIndex].qualityDiv,
                // 責任区分
                isRes: this.inputList[this.editedIndex].blameDiv,
                // 有償無償
                isPaid: this.inputList[this.editedIndex].freeCostDiv,
                // 理由区分
                freeReasonSelected: this.inputList[this.editedIndex].reasonCd,
                // 備考欄
                remarksTextbox: this.inputList[this.editedIndex].remarksTextbox,
                // 処理区分
                processDiv: this.inputList[this.editedIndex].processDiv,
                // 出荷先
                toSid: this.inputList[this.editedIndex].toSid,
                // 出荷先名
                toName: this.inputList[this.editedIndex].toName,
                // 拠点コード
                toBaseCd: this.inputList[this.editedIndex].toBaseCd,
                // 輸送会社名
                carrierCompName: this.inputList[this.editedIndex].carrierCompName,
                // 路線伝票名
                deliveryTraderName: this.inputList[this.editedIndex].deliveryTraderName,
                // 遷移元画面
                screenName: appConfig.SCREEN_ID.P_TMP_903,
                // 出荷先名
                shippingNm:
                  this.inputList[this.editedIndex].toBaseCd +
                  "（" +
                  this.inputList[this.editedIndex].toName +
                  "）",
              },
            },
          });
        } else if (this.inputList[this.editedIndex].planningSection == "入荷") {
          // 計画区分が"入荷"の場合、入荷予定修正削除画面へ遷移
          // 修正画面へ渡す値を設定します。
          this.$router.push({
            name: appConfig.SCREEN_ID.P_RCV_004,
            params: {
              // 検索領域
              detailsList: {
                headerList: this.searchParam,
                // 取引先名
                suppliersSelectedName: this.supplierList.find(
                  (data) => data.value === this.suppliersSelected
                ).text,
                // 取引先リスト
                supplierList: this.supplierList,
                // 伝票SID
                inOutListSub: this.inputList[this.editedIndex].shipSlipSid,
                // 作業日
                inOutScheduleDate: this.inputList[this.editedIndex].processingDate,
                // 伝票No
                inOutListNo: this.inputList[this.editedIndex].voucherNo,
                // 品質区分
                arrivalStatusSelected: this.inputList[this.editedIndex].qualityDiv,
                // 責任区分
                isRes: this.inputList[this.editedIndex].blameDiv,
                // 有償無償
                isPaid: this.inputList[this.editedIndex].freeCostDiv,
                // 理由区分
                freeReasonSelected: this.inputList[this.editedIndex].reasonCd,
                // 備考欄
                remarksTextbox: this.inputList[this.editedIndex].remarksTextbox,
                // 処理区分
                processDiv: this.inputList[this.editedIndex].processDiv,
                // 入荷先
                fromSid: this.inputList[this.editedIndex].fromSid,
                // 入荷先名称
                fromName: this.inputList[this.editedIndex].fromName,
                // 入荷先拠点CD
                fromBaseCd: this.inputList[this.editedIndex].fromBaseCd,
                // 入荷元名
                arrivalNm:
                  this.inputList[this.editedIndex].fromBaseCd +
                  "（" +
                  this.inputList[this.editedIndex].fromName +
                  "）",
                // 入荷元名(省略)
                arrivalOmitNm:
                  commonUtil.omit(
                    this.inputList[this.editedIndex].fromBaseCd,
                    appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH
                  ) +
                  "（" +
                  this.inputList[this.editedIndex].fromName +
                  "）",
                // 営業所SID
                officeCd: this.supplierList.find((data) => data.value === this.suppliersSelected)
                  .clientSid,
              },
              // 遷移元画面
              screenName: appConfig.SCREEN_ID.P_TMP_903,
            },
          });
        } else if (this.inputList[this.editedIndex].planningSection == "出荷") {
          // 計画区分が"出荷"の場合、出荷予定修正削除画面へ遷移
          // 修正画面へ渡す値を設定します。
          this.$router.push({
            name: appConfig.SCREEN_ID.P_SHP_004,
            params: {
              detailsList: {
                // 検索条件
                headerList: this.searchParam,
                // 取引先名
                suppliersSelectedName: this.supplierList.find(
                  (data) => data.value === this.suppliersSelected
                ).text,
                // 取引先リスト
                supplierList: this.supplierList,
                // 伝票SID
                inOutListSub: this.inputList[this.editedIndex].shipSlipSid,
                // 作業日
                inOutScheduleDate: this.inputList[this.editedIndex].processingDate,
                // 伝票No
                inOutListNo: this.inputList[this.editedIndex].voucherNo,
                // 品質区分
                arrivalStatusSelected: this.inputList[this.editedIndex].qualityDiv,
                // 責任区分
                isRes: this.inputList[this.editedIndex].blameDiv,
                // 有償無償
                isPaid: this.inputList[this.editedIndex].freeCostDiv,
                // 理由区分
                freeReasonSelected: this.inputList[this.editedIndex].reasonCd,
                // 備考欄
                remarksTextbox: this.inputList[this.editedIndex].remarksTextbox,
                // 処理区分
                processDiv: this.inputList[this.editedIndex].processDiv,
                // 出荷先
                toSid: this.inputList[this.editedIndex].toSid,
                // 出荷先名称
                toName: this.inputList[this.editedIndex].toName,
                // 出荷先拠点CD
                toBaseCd: this.inputList[this.editedIndex].toBaseCd,
                // 輸送会社区分
                carrierCompCd: this.inputList[this.editedIndex].carrierCompCd,
                // 路線伝票SID
                deliveryTraderSid: this.inputList[this.editedIndex].deliveryTraderSid,
                // 路線業者名
                deliveryTraderName: this.inputList[this.editedIndex].deliveryTraderName,
                // お届け先日
                deliveryDate: this.inputList[this.editedIndex].deliveryDate,
                // お届け先名
                shipToName: this.inputList[this.editedIndex].shipToName,
                // 住所
                shipToAddressDetail: this.inputList[this.editedIndex].shipToAddressDetail,
                // 出荷先名
                shippingNm:
                  this.inputList[this.editedIndex].toBaseCd +
                  "（" +
                  this.inputList[this.editedIndex].toName +
                  "）",
                // 出荷先名(BaseCd付き)（省略）
                shippingOmitNm:
                  commonUtil.omit(
                    this.inputList[this.editedIndex].toBaseCd,
                    appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH
                  ) +
                  "（" +
                  this.inputList[this.editedIndex].toName +
                  "）",
                // 営業所SID
                officeCd: this.supplierList.find((data) => data.value === this.suppliersSelected)
                  .clientSid,
              },
              // 遷移元画面
              screenName: appConfig.SCREEN_ID.P_TMP_903,
            },
          });
        }
      } else {
        //遷移しない
        return;
      }
    },

    // 引当結果確認画面遷移
    toAllocationResult(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      //入力チェック
      if (this.$refs.form.validate()) {
        // 引当結果確認画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_907,
          params: {
            distributionProcessingPlanAllocationResultList: {
              // 検索条件
              headerList: this.searchParam,
              // 取引先名
              suppliersSelectedName: this.supplierList.find(
                (data) => data.value === this.suppliersSelected
              ).text,
              // 伝票SID
              inOutListSub: this.inputList[this.editedIndex].shipSlipSid,
              // 作業日
              inOutScheduleDate: this.inputList[this.editedIndex].processingDate,
              // 伝票No
              inOutListNo: this.inputList[this.editedIndex].voucherNo,
              // // 入出荷先名称
              // fromToName: this.inputList[this.editedIndex].fromToName,
              // 入出荷先名称
              fromToName:
                this.inputList[this.editedIndex].toBaseCd +
                "（" +
                this.inputList[this.editedIndex].toName +
                "）",
              // 輸送会社名
              carrierCompName: this.inputList[this.editedIndex].carrierCompName,
              // 路線業者名
              deliveryTraderName: this.inputList[this.editedIndex].deliveryTraderName,

              // 日付ラベル
              processingDateLabel: this.processingDateLabel,
              // 遷移元
              screenName: appConfig.SCREEN_ID.P_TMP_903,
            },
          },
        });
      } else {
        //遷移しない
        return;
      }
    },

    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },
    /**
     * 一覧チェックボックス処理
     */
    selectCheck(index) {
      //チェックした行のインデックス
      this.checkIndex = index;
    },

    /**
     * 流通加工実績一覧画面遷移
     */
    openAchieveAdd(item) {
      // 入力チェック
      if (!this.validate()) {
        return;
      }

      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      //選択した行の有償/無償区分が有償だった場合
      if (this.inputList[this.editedIndex].isPaid == "有償") {
        this.isPaid = "01";
        //選択した行の有償/無償区分が無償だった場合
      } else if (this.inputList[this.editedIndex].isPaid == "無償") {
        this.isPaid = "02";
      }
      // 修正画面へ渡す値を設定します。
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TMP_908,
        params: {
          // 取引先
          suppliersSelected: this.setSrhSupplier(),
          // 作業日
          workingDate: item.processingDate,
          // 日付ラベル
          processingDateLabel: this.processingDateLabel,
          // 伝票No
          voucherNo: item.voucherNo,
          // 出荷予定日
          shippinglScheduleDate: item.shippingNo,
          // 出荷No
          shippingNo: item.shippingNo,
          // 出荷先
          shippingTo: item.shippingTo,
          // 備考
          remarksTextbox: item.remarksTextbox,
          // 有償 / 無償
          // 無償理由
          isPaid: this.isPaid,
          name: "DistributionAchieveAdd",
        },
      });
    },

    /**
     * 加工計画一覧画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 加工計画一覧画面：計画区分コードリストボックス取得処理
     */
    setPlanningSection() {
      for (var i = 0; i < this.planningSectionList.length; i++) {
        if (this.planningSectionList[i].value == this.planningSection) {
          return this.planningSectionList[i].name;
        }
      }
    },

    /**
     * 加工計画一覧画面：資材引当結果コードリストボックス取得処理
     */
    setAllocationResult() {
      for (var i = 0; i < this.allocationResultList.length; i++) {
        if (this.allocationResultList[i].value == this.allocationResult) {
          return this.allocationResultList[i].name;
        }
      }
    },

    //検索ボタン押下処理(API)
    getApiList() {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      // 取引先存在チェック
      if (this.isEmpty(this.suppliersSelected)) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      if (
        // FromToの日付、入力チェック
        new Date(this.arrProcessingDateFrom).getTime() >
        new Date(this.arrProcessingDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.backPage || this.$route.params.from) {
          // 他画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }

        if (inputCheckResult) {
          // 全選択を初期化
          this.isAllSelected = false;

          //検索条件の保持
          this.searchParam.searchSuppliersSelected = this.suppliersSelected;
          this.searchParam.searchVoucherNo = this.voucherNo;
          this.searchParam.searcharrProcessingDateFrom = this.arrProcessingDateFrom;
          this.searchParam.searcharrProcessingDateFromCal = this.arrProcessingDateFromCal;
          this.searchParam.searcharrProcessingDateTo = this.arrProcessingDateTo;
          this.searchParam.searcharrProcessingDateToCal = this.arrProcessingDateToCal;
          this.searchParam.searchPlanningSection = this.planningSection;
          this.searchParam.searchAllocationResult = this.allocationResult;
          this.searchParam.searcharrCreatedDateFrom = this.arrCreatedDateFrom;
          this.searchParam.searcharrCreatedDateFromCal = this.arrCreatedDateFromCal;

          // ローディング画面表示ON
          this.loadingCounter = 1;
          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          // 取引先SID
          config.params.clientSid = this.suppliersSelected;
          // 作業日(FROM)
          config.params.scheduleDateFrom = dateTimeHelper.convertUTC(this.arrProcessingDateFrom);
          // 作業日(TO)
          config.params.scheduleDateTo = dateTimeHelper.convertUTC(this.arrProcessingDateTo);
          // 作成日
          if (this.arrCreatedDateFrom) {
            config.params.createDatetime = dateTimeHelper.convertUTC(this.arrCreatedDateFrom);
          }
          // 伝票No
          if (this.voucherNo) {
            config.params.inOutListNo = this.voucherNo;
          }

          // 引当結果
          if (this.allocationResult) {
            config.params.allotmentResult = this.allocationResult;
          }

          // 計画区分
          config.params.inOutProcessingDiv = this.planningSection;
          // 削除Flg
          config.params.deleteFlg = "0";

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_DSTSCHEDULELIST, config)
              .then((response) => {
                this.inputList.splice(0);
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  const list = [];
                  // レスポンスで取得した明細行データをリストに詰める
                  jsonData.resIdv.processingSchedule.forEach((row) => {
                    list.push({
                      //出荷伝票SID
                      shipSlipSid: row.inOutListSub,

                      //作業日()
                      processingDate: dateTimeHelper
                        .convertUTC2JST(row.inOutScheduleDate)
                        .substr(0, 10),
                      //伝票No
                      voucherNo: row.inOutListNo,
                      //有償/無償区分
                      freeCostDiv: row.freeCostDiv,
                      //有償/無償区分名称
                      isPaid: row.freeCostDivName,
                      //理由区分
                      reasonCd: row.reasonCd,
                      //備考
                      remarksTextbox: row.remarks,
                      //作成日時
                      createdDate: dateTimeHelper.convertUTC2JST(row.createDatetime).substr(0, 16),
                      //引当結果
                      allocationResult: row.allotmentResult,
                      //引当結果名称
                      allotmentResultName: row.allotmentResultName,
                      //計画区分
                      planningSectionDiv: row.inOutProcessingDiv,
                      //計画区分名称
                      planningSection: row.inOutProcessingDivName,
                      // 入荷先
                      fromSid: row.fromSid,
                      // 入荷先名称
                      fromName: row.fromName,
                      // 入荷先拠点CD
                      fromBaseCd: row.fromBaseCd,
                      // 出荷先
                      toSid: row.toSid,
                      // 出荷先名称
                      toName: row.toName,
                      // 出荷先拠点CD
                      toBaseCd: row.toBaseCd,
                      // // 拠点CD
                      // baseCd: row.baseCd,
                      //輸送会社名
                      carrierCompName: row.carrierCompName,
                      //輸送会社区分
                      carrierCompCd: row.carrierCompCd,
                      //路線業者SID
                      deliveryTraderSid: row.deliveryTraderSid,
                      //路線業者名
                      deliveryTraderName: row.deliveryTraderName,
                      //責任区分
                      blameDiv: row.blameDiv,
                      //品質区分
                      qualityDiv: row.qualityDiv,
                      //処理区分
                      processDiv: row.processDiv,
                      //お届け先日
                      deliveryDate: row.deliveryDate,
                      //届け先名
                      shipToName: row.shipToName,
                      //住所
                      shipToAddressDetail: row.addressDetail,
                      //出力済み
                      printFlg: row.printFlg,
                      //チェックボックス
                      check: false,
                    });
                  });
                  // テーブルデータリストに明細行データを設定
                  this.inputList = list;
                  // 総件数
                  this.totalCount = this.inputList.length;
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },
    // 取引先API接続
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所コードを設定
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先取得有無を設定
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              // レスポンスで取得したデータをリストに詰める
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                  clientSid: row.compSid,
                });
              });
              // 取引先リストに取得したデータを設定
              this.supplierList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            if (!this.$route.params.backPage && !this.$route.params.from) {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            }
          })
          .then(() => {});
      });
    },

    /**
     * コードマスタを取得します。
     */
    getMstCode() {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // 引当結果を取得します。
      const allocationResultList = getParameter.getCodeMst(appConfig.CODETYPE.ALLOTMENTRESULT_DIV);

      // 計画区分を取得します。
      const planningSectionList = getParameter.getCodeMst(appConfig.CODETYPE.PROCESSING_DIV);

      // CSV出力済み情報を取得します。
      const printList = getParameter.getCodeMst(appConfig.CODETYPE.PRINT_FLG);

      // 非同期処理を開始します。
      Promise.all([allocationResultList, planningSectionList, printList])
        // 成功時の処理です。
        .then((result) => {
          // 引当結果を設定します。
          this.allocationResultList = result[0];

          // 計画区分を設定します。
          this.planningSectionList = result[1];

          // CSV出力済み情報を設定します。
          this.printList = result[2];
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          //this.simpleDialog = true;
          this.infoDialog.isOpen = true;

          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          if (!this.$route.params.backPage && !this.$route.params.from) {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
          }
        });
    },

    // 入力項目のバリデーション
    validate() {
      // 入力チェック結果フラグ
      this.alertMessage = "";
      this.alertSupplierMessage = "";

      // 取引先存在チェック
      if (this.isEmpty(this.suppliersSelected)) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return false;
      }

      // 上記外の場合はチェックを行う。
      return this.$refs.form.validate();
    },

    /**
     * ページング処理
     */
    showList() {
      return this.inputList;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    // 作業日(FROM)
    arrProcessingDateFromCal: {
      handler(val) {
        this.arrProcessingDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    // 作業日(TO)
    arrProcessingDateToCal: {
      handler(val) {
        this.arrProcessingDateTo = this.formatDate(val);
      },
      deep: true,
    },
    // 作成日(FROM)
    arrCreatedDateFromCal(val) {
      this.arrCreatedDateFrom = this.formatDate(val);
    },
    planningSection() {
      this.changePlanningSection();
    },
    suppliersSelected(oldValue) {
      if (oldValue != null && oldValue != "") {
        //処理なし
      }
      if (!this.$route.params.suppliersSelected) {
        this.alertSupplierMessage = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

.finish-sing {
  color: rgb(0, 0, 0);
  font-weight: normal;
}

.shortage-sing {
  color: rgb(255, 60, 60);
  font-weight: normal;
}

.unregistered-sing {
  color: rgb(255, 60, 60);
  font-weight: normal;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

//検索条件：プルダウン
.search-autocomplete-supplier {
  width: 18rem;
}
</style>
