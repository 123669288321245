<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionAchieveAdd" />
      <!-- （画面名）加工実績登録-->
      <v-container fluid>
        <!-- 検索項目-->
        <v-row class="search-row">
          <div class="search-autocomplete-supplier first-search-item" style="width: 400px">
            <div>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersSelected"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>

          <!-- 空白 -->

          <span class="item-spacer">&nbsp;</span>
          <div class="textbox-voucherNo" style="width: 350px">
            <!-- 伝票No-->
            <v-text-field
              readonly
              dense
              class="txt-single"
              v-model="txt_voucherNo"
              maxlength="50"
              :label="$t('label.lbl_voucherNo')"
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <div class="date-style">
            <!-- 作業日-->
            <v-text-field
              readonly
              dense
              v-model="workingDate"
              :label="$t('label.lbl_workingDate')"
              class="txt-single"
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <div class="search-autocomplete">
            <!-- 品質区分-->
            <v-text-field
              readonly
              dense
              v-model="arrivalStatusSelected"
              :label="$t('label.lbl_arrivalStatus')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
        </v-row>

        <v-row>
          <div class="search-row-exeBtn first-search-item padding-bottom">
            <div style="float: left">
              <v-btn-toggle
                :disabled="true"
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <!-- 有償/無償-->

                <v-btn
                  :disabled="true"
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </div>

            <!-- 空白 -->

            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="search-autocomplete" style="float: left">
              <!-- 無償理由選択プルダウン-->

              <v-text-field
                dense
                readonly
                v-model="freeReasonSelected"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :label="$t('label.lbl_freeReason')"
              ></v-text-field>
            </div>

            <!-- 空白 -->

            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 備考欄-->
            <div class="textbox-remarks">
              <v-text-field
                readonly
                dense
                v-model="remarks"
                maxlength="50"
                :label="$t('label.lbl_remarksTextbox')"
                class="txt-single"
              />
            </div>
          </div>
        </v-row>

        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->

              <v-btn class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>

            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 登録ボタン-->

              <v-btn class="post-btn" value="1" v-on:click="rcvAchAdd">
                {{ $t("btn.btn_insert") }}
              </v-btn>

              <!-- メッセージダイアログ-->

              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="addSuccsess"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>

      <!-- データテーブル（項目） -->

      <v-form ref="editedList" @submit.prevent lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- 品番/品名 -->
            <template v-slot:[`item.productCnCd`]="{ item }">
              <div v-if="item.productName.length > 10">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}
                    </div>
                    <div class="text-overflow">
                      {{ item.productName }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.productName }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.productName }}</div>
              </div>
            </template>

            <!-- 付帯品番 -->
            <template v-slot:[`item.accessoryCd`]="{ item }">
              <span>
                <!-- 付帯品番を表示 -->
                {{ item.accessoryCd }}
              </span>
            </template>

            <!-- 出荷先 -->
            <template v-slot:[`item.shippingTo`]="{ item }">
              <span>
                <!-- 出荷先を表示 -->
                {{ item.shippingTo }}
              </span>
            </template>

            <!-- ロット -->
            <template v-slot:[`item.lotNo`]="{ item }">
              <span>
                <!-- ロットを表示 -->
                {{ item.lotNo }}
              </span>
            </template>

            <!-- 計画数 -->
            <template v-slot:[`item.planQuantity`]="{ item }">
              <span>
                <!-- 計画数を表示 -->
                {{ item.planQuantity }}
              </span>
            </template>

            <!-- 収容数 -->
            <template v-slot:[`item.capacityQty`]="{ item }">
              <span>
                <!-- 収容数を表示 -->
                {{ item.capacityQty }}
              </span>
            </template>

            <!-- 梱包数 -->
            <template v-slot:[`item.numberOfPackages`]="{ item }">
              <!-- 梱包数を表示 -->
              <span>{{ item.numberOfPackages }}</span>
            </template>

            <!-- 差異 -->
            <template v-slot:[`item.difference`]="{ item }">
              <div v-if="item.colorFlg == true" class="align-right" style="color: red">
                {{ item.difference }}
              </div>
              <div v-else-if="item.colorFlg == false" class="align-right">
                {{ item.difference }}
              </div>
            </template>
            <template v-slot:[`item.quantity`]="{ item, index }">
              <div id="table-actualQuantity" v-if="item.completeFlg == '1'">
                <!-- 数量-->
                {{ item.quantity }}
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  dense
                  v-model="item.quantity"
                  class="input-number"
                  @change="changeQuantity(item, index)"
                  @click="clickPop(item.ballSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckBara,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'quantityCheck' + index"
                />
              </div>
            </template>

            <!-- 資材ボタン -->
            <template v-slot:[`item.materials`]="{ item, index }">
              <v-btn small @click="openMaterials(item, index)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- 戻るボタンのダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :firstPageFlag="infoDialog.firstPageFlag"
          :rcvSchListFlg="infoDialog.rcvSchListFlg"
        />
      </v-dialog>
      <!-- 資材ダイアログ-->
      <v-dialog v-model="dialogMaterials" :max-width="800" persistent>
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_materials") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="materialsProductCnCd"
                    :label="$t('label.lbl_productCnCd')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="materialsAccessoryCd"
                    :label="$t('label.lbl_accessoryCd')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="materialsShippingTo"
                    :label="$t('label.lbl_shippingTo')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="materialsPlanQuantity"
                    :label="$t('label.lbl_planQuantity')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="materialsNumberOfPackages"
                    :label="$t('label.lbl_numberOfPackages')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <!-- 資材テーブルデータ -->
              <!-- <v-row> -->
              <v-data-table
                id="materialsData"
                fixed-header
                :headers="materialsHeader"
                :items="materialsList"
                disable-sort
                :hide-default-footer="true"
              >
                <!-- No -->
                <template v-slot:[`item.materialsNo`]="{ item }">
                  <span>
                    <!-- Noを表示 -->
                    {{ item.materialsNo }}
                  </span>
                </template>

                <!-- 資材 -->
                <template v-slot:[`item.materials`]="{ item }">
                  <span>
                    <!-- 資材を表示 -->
                    {{ item.materials }}
                  </span>
                </template>

                <!-- 数量 -->
                <template v-slot:[`item.materialsQty`]="{ item }">
                  <span>
                    <!-- 数量を表示 -->
                    {{ item.materialsQty }}
                  </span>
                </template>

                <!-- 差異 -->
                <template v-slot:[`item.materialsDifference`]="{ item }">
                  <div v-if="item.materialsColorFlg == true" class="align-right" style="color: red">
                    {{ item.materialsDifference }}
                  </div>
                  <div v-else-if="item.materialsColorFlg == false" class="align-right">
                    {{ item.materialsDifference }}
                  </div>
                </template>

                <!-- 実績数 -->
                <template v-slot:[`item.materialsAchieveQty`]="{ item }">
                  <span>
                    <!-- 実績数を表示 -->
                    {{ item.materialsAchieveQty }}
                  </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <div class="btn-area">
                <!-- 閉じるボタン -->
                <v-btn class="other-btn" @click="closeDialogMaterials">
                  {{ $t("btn.btn_close") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
// import { controlNumberUtil } from "../../assets/scripts/js/ControlNumberUtil";
// import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: appConfig.SCREEN_ID.P_TMP_908,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },

  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    loadingCounter: 0,
    // バリデーションチェックリスト
    editedList: [],
    // 取引
    suppliersSelected: "",
    // 品質区分
    arrivalStatusSelected: "",
    // 有償無償
    isPaid: "",
    freeCostDivList: [],
    // 無償理由
    freeReasonSelected: "",
    // メニュー
    openMenu: null,
    // メッセージダイアログ
    dialogMessage: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    //資材ダイアログ
    dialogMaterials: false,
    //資材ダイアログの品番/品名
    materialsProductCnCd: "",
    //資材ダイアログの付帯品番
    materialsAccessoryCd: "",
    //資材ダイアログの出荷先
    materialsShippingTo: "",
    //資材ダイアログの計画数
    materialsPlanQuantity: "",
    //資材ダイアログの梱包数
    materialsNumberOfPackages: "",
    //資材ヘッダー
    materialsHeader: [
      { text: "No", value: "materialsNo", align: "left", width: "5%" },
      { text: "資材", value: "materials", align: "left", width: "20%" },
      { text: "数量", value: "materialsQty", align: "right", width: "15%" },
      { text: "差", value: "materialsDifference", align: "right", width: "15%" },
      { text: "実績数", value: "materialsAchieveQty", align: "right", width: "15%" },
    ],
    //資材データ
    materialsList: [],
    // 一覧index
    editedIndex: -1,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    simpleDialog: false,
    // メッセージダイアログ
    infoDialog: {
      simpleDialog: false,
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
      rcvSchListFlg: false,
    },
    productListIdx: -1,
    // 加工実績一覧ヘッダ
    headerItems: [
      {
        // No
        text: "No",
        value: "inListSubNo",
        width: "2%",
        sortable: false,
        align: "center",
      },
      {
        // 品番/品名
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "13%",
        align: "left",
        sortable: false,
      },
      {
        // 付帯品番
        text: i18n.tc("label.lbl_accessoryCd"),
        value: "accessoryCd",
        width: "13%",
        align: "left",
        sortable: false,
      },
      {
        // 出荷先
        text: i18n.tc("label.lbl_shippingTo"),
        value: "shippingTo",
        width: "13%",
        align: "left",
        sortable: false,
      },
      // ロット
      { text: i18n.tc("label.lbl_lot"), value: "lotNo", width: "9%", align: "left" },
      {
        // 計画数
        text: i18n.tc("label.lbl_planQuantity"),
        value: "planQuantity",
        width: "9%",
        align: "right",
        sortable: false,
      },
      {
        // 収容数
        text: i18n.tc("label.lbl_capacityQty"),
        value: "capacityQty",
        width: "9%",
        align: "right",
        sortable: false,
      },
      {
        // 梱包数
        text: i18n.tc("label.lbl_numberOfPackages"),
        value: "numberOfPackages",
        width: "9%",
        align: "right",
        sortable: false,
      },

      {
        // 差
        text: i18n.tc("label.lbl_difference"),
        value: "difference",
        width: "5%",
        align: "right",
      },
      {
        // 数量
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "9%",
        align: "right",
      },
      {
        //資材
        text: i18n.tc("label.lbl_materials"),
        value: "materials",
        align: "center",
        width: "6%",
      },
    ],

    inputList: [],

    checkFlg: false,

    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),

      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),

      isRangeCheckBara: (value) =>
        0 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMoreOne"),

      limitNumLength: (value) =>
        String(value).replaceAll(",", "").length < 10 || i18n.tc("check.chk_limitNumLength"), // 文字数の制約
    },
  }),

  created() {
    // 実績登録画面で必要な値
    // 取引先(実績登録画面表示用)
    this.suppliersSelected = this.$route.params.suppliersSelected;
    // 伝票No
    this.txt_voucherNo = this.$route.params.voucherNo;
    // 作業日
    this.workingDate = this.$route.params.workingDate;
    // 有償無償
    this.isPaid = this.$route.params.isPaid;
    //有償無償区分が無償の場合、無償理由名を設定
    if (this.isPaid == "02") {
      // 無償理由名
      this.freeReasonSelected = "当社都合";
    }
    // 品質区分
    this.arrivalStatusSelected = "良品";
    //備考
    this.remarks = this.$route.params.remarksTextbox;
    // コードマスタ取得
    this.getMstCode();
    //テーブルデータ
    var data = [
      {
        inListSubNo: "1",
        productCnCd: "06141445",
        productName: "財布",
        accessoryCd: "20230602",
        shippingTo: "出荷先A",
        lotNo: "0123",
        planQuantity: "100",
        capacityQty: "10",
        numberOfPackages: "8",
        difference: "-2",
        quantity: "10",
        completeFlg: "1",
      },
      {
        inListSubNo: "2",
        productCnCd: "06141446",
        productName: "カラーBOX",
        accessoryCd: "20230612",
        shippingTo: "出荷先B",
        lotNo: "0124",
        planQuantity: "200",
        capacityQty: "5",
        numberOfPackages: "20",
        difference: "0",
        quantity: "20",
        completeFlg: "1",
      },
      {
        inListSubNo: "3",
        productCnCd: "06141447",
        productName: "バッグ",
        accessoryCd: "20230622",
        shippingTo: "出荷先C",
        lotNo: "0125",
        planQuantity: "50",
        capacityQty: "1",
        numberOfPackages: "50",
        difference: "-50",
        quantity: "0",
        completeFlg: "0",
      },
    ];
    // 差異列：文字を色付け
    for (var i = 0; i < data.length; i++) {
      if (data[i].difference.replaceAll(",", "") < 0) {
        data[i].colorFlg = true;
      } else {
        data[i].colorFlg = false;
      }
    }
    this.inputList = data;
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(入荷予定一覧から値受け取る)
     */

    init() {
      // ceatedにて記載
    },

    /**
     * コードマスタ取得
     */

    getMstCode() {
      // ローディング画面表示ON

      this.loadingCounter = 1;

      // 有償無償区分

      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);

      // 責任区分

      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);

      Promise.all([freeCostList, blameList])

        .then((result) => {
          // 画面の初期値を設定します。

          this.freeCostDivList = result[0];

          // 責任区分の最初のなしを削除

          result[1].splice(0, 1);

          this.blameDivList = result[1];
        })

        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        })

        .finally(() => {
          // ローディング画面表示OFF

          this.loadingCounter = 0;
        });
    },

    /**
     * メニューダイアログ処理
     */

    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷実績数、差計算
     */

    changeQty(val, index) {
      // 数量、数値変換

      var quantity = commonUtil.zen2han(val.quantity).replaceAll(",", "");

      if (Number(quantity) || quantity == 0) {
        // 差の計算

        // 梱包数、数値変換

        var numberOfPackages = commonUtil

          .zen2han(this.inputList[index].numberOfPackages)

          .replaceAll(",", "");

        // 差の計算（梱包数-数量）

        var subtraction = numberOfPackages - quantity;

        if (Math.sign(subtraction) == 1) {
          this.inputList[index].difference = "+" + commonUtil.formatToCurrency(Number(subtraction));
          val.colorFlg = false;
        } else if (Math.sign(subtraction) == 0 || Math.sign(subtraction) == -1) {
          this.inputList[index].difference = commonUtil.formatToCurrency(Number(subtraction));
          val.colorFlg = true;
        }
      }
    },

    /**
     * 戻るボタン処理
     **/

    backbtn() {
      this.updateDialogMessage = true;

      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 一覧画面へ渡す値を設定
     */

    updateOk() {
      if (this.errorFlg) {
        this.updateDialogMessage = false;

        this.checkFlg = true;

        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_903,

          params: {
            suppliersSelected: this.suppliersNm,

            arrivalSelected: this.arrivalSid,

            txt_slipNo: this.inListNo,

            arrAchieveDateFrom: this.arrAchieveDateFrom,

            arrAchieveDateTo: this.arrAchieveDateTo,

            arrAchieveDateFromCal: this.arrAchieveDateFromCal,

            arrAchieveDateToCal: this.arrAchieveDateToCal,

            arrivalStatusSelected: this.arrivalStatusSelectedList,

            // 画面遷移フラグ

            checkFlg: this.checkFlg,

            // 入荷元一覧

            arrivalList: this.$route.params.arrivalList,

            arrivalNm: this.$route.params.arrivalSelected,

            // 入荷予定一覧検索条件

            searchParam: this.$route.params.searchParam,
          },
        });
      } else {
        this.updateDialogMessage = false;

        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */

    updateNg() {
      this.updateDialogMessage = false;
    },
    //資材ダイアログ表示
    openMaterials(item, index) {
      var materialsInfoList = [
        {
          materialsNo: "1",
          materials: "段ボール M",
          materialsQty: "50",
          materialsDifference: "-2",
          materialsAchieveQty: "48",
        },
        {
          materialsNo: "2",
          materials: "エアーキャップ L",
          materialsQty: "50",
          materialsDifference: "-2",
          materialsAchieveQty: "48",
        },
      ];
      // 差異列：文字を色付け
      for (var i = 0; i < materialsInfoList.length; i++) {
        if (materialsInfoList[i].materialsDifference.replaceAll(",", "") < 0) {
          materialsInfoList[i].materialsColorFlg = true;
        } else {
          materialsInfoList[i].materialsColorFlg = false;
        }
      }
      this.materialsList = materialsInfoList;

      //資材ダイアログの品番/品名
      this.materialsProductCnCd = this.inputList[index].productCnCd;
      //資材ダイアログの付帯品番
      this.materialsAccessoryCd = this.inputList[index].accessoryCd;
      //資材ダイアログの出荷先
      this.materialsShippingTo = this.inputList[index].shippingTo;
      //資材ダイアログの計画数
      this.materialsPlanQuantity = this.inputList[index].planQuantity;
      //資材ダイアログの梱包数
      this.materialsNumberOfPackages = this.inputList[index].numberOfPackages;
      this.dialogMaterials = true;
    },
    /**
     * 資材ダイアログ閉じる
     */
    closeDialogMaterials() {
      this.dialogMaterials = false;
    },

    /**
     * 入力カンマ処理 数量
     */

    changeQuantity(val, index) {
      var quantity = commonUtil.zen2han(val.quantity).replaceAll(",", "");

      quantity = quantity.replace(/[^0-9]/gi, "");

      if (quantity.length > 9) {
        quantity = quantity.substring(0, 9);
      }

      if (!isNaN(Number(quantity))) {
        this.inputList[index].quantity = commonUtil.formatToCurrency(Number(quantity));
      }

      // 数値変換

      this.changeQty(val, index);
    },

    clickPop(val) {
      let myName = event.target;

      let pop = val;

      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }

      myName.value = pop;
    },

    /**
     * 登録ボタン押下
     */

    rcvAchAdd() {
      let cnt = 0;

      let cntChenge = [1];

      if (this.$refs.editedList.validate()) {
        // 明細部、入力チェック実施

        for (var i = 0; i < this.inputList.length; i++) {
          var checkQuantity = Number(
            commonUtil.zen2han(this.inputList[i].quantity).replaceAll(",", "")
          );

          // 数値チェック

          if (isNaN(checkQuantity)) {
            // 数量が数値でない時

            cnt = i + 1;
          } else {
            // 処理なし
          }

          // 整数チェック

          if (Number.isInteger(checkQuantity)) {
            // 処理なし
          } else {
            // 数量が整数でない時

            cnt = i + 1;
          }

          //if (this.inputList[i].oriBallSu == this.inputList[i].ballSu) {
          // 修正していないレコードを探し、レコード数を数える

          //cntChenge.push(this.inputList[i]);
          //} else {
          // 処理なし
          //}
        }

        // 明細部、入力エラーなし（カウント0）かつ、修正していないレコードがinputListのレコード数と一致しない場合、確認メッセージ表示

        if (cnt == 0 && cntChenge.length != this.inputList.length) {
          this.addFlg = true;

          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");

          this.ConfirmDialog.isOpen = true;

          this.ConfirmDialog.screenFlag = true;

          this.ConfirmDialog.changeFlag = false;
        } else {
          if (cntChenge.length == this.inputList.length) {
            // 修正点がなかった時

            this.errorFlg = false;

            this.updateDialogMessage = true;

            this.backMessage = messsageUtil.getMessage("P-RCV-006_001_E");
          } else if (this.$refs.editedList.validate()) {
            // 明細部、入力エラー時（カウント1）
            // 処理なし
          }
        }
      } else {
        // 処理なし
      }
    },

    addSuccsess() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = appConfig.DIALOG.title;
      this.simpleDialog = true;
      // 遷移ボタン
      this.infoDialog.homePageFlag = true;
      this.infoDialog.rcvSchListFlg = true;
      this.infoDialog.firstPageFlag = false;
    },
  },

  computed: {},

  watch: {
    // eslint-disable-next-line no-unused-vars

    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },

    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>

<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;

  padding-right: 0;

  font-size: large;

  pointer-events: none;
}

//新製品登録時、色変更

.isD {
  background-color: $color-warning !important;

  //background-color: $color-error !important;
}

.padding-bottom {
  margin-bottom: 20px;
}

.menu-btn {
  margin: 0.1rem 0.2rem;

  // font-size: $menu-fontsize !important;

  font-weight: bold;
}

::v-deep #btn-logout {
  img {
    width: 20px;

    height: 20px;
  }
}

.text-overflow {
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//検索条件：プルダウン
.search-autocomplete-supplier {
  width: 21%;
}
#materialsData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }
    //テーブル項目部分
    // th::after {
    //   content: "*" !important;
    //   color: red;
    // }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;
      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}

#lbl-user-name {
  // font-size: $menu-fontsize !important;

  font-weight: bold;

  &:before {
    content: "";

    display: inline-block;

    width: 20px;

    height: 20px;

    background-image: url("../../assets/img/user_menu_icon.png");

    background-size: contain;

    vertical-align: middle;
  }
}
</style>
