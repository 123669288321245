<!-- 加工引当結果確認画面-->
<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionProcessingPlanAllocationResult" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="textbox-search-supplier-readonly first-search-item" style="width: 500px">
              <v-text-field
                dense
                readonly
                v-model="supplier"
                :hint="supplier"
                persistent-hint
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                pa-3
              >
              </v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作業日 -->
            <div class="textbox-date-readonly">
              <v-text-field
                dense
                v-model="processingDate"
                :label="processingDateLabel"
                :hint="processingDate"
                persistent-hint
                class="txt-single"
                readonly
                pa-3
              >
              </v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 伝票No -->
            <div class="search-textbox-voucherNo">
              <v-text-field
                dense
                v-model="voucherNo"
                :label="$t('label.lbl_voucherNo')"
                :hint="voucherNo"
                class="txt-single"
                persistent-hint
                readonly
                pa-3
              >
              </v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先 -->
            <div class="textbox-shipping-to-readonly first-search-item">
              <v-text-field
                dense
                v-model="fromToName"
                :label="$t('label.lbl_shippingTo')"
                :hint="fromToName"
                persistent-hint
                class="txt-single"
                readonly
                pa-3
              >
              </v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 輸送会社名 -->
            <div class="textbox-shipping-company-name-readonly">
              <v-text-field
                dense
                v-model="shippingCompanyName"
                :label="$t('label.lbl_shippingCompanyName')"
                :hint="shippingCompanyName"
                persistent-hint
                class="txt-single"
                readonly
                pa-3
              >
              </v-text-field>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn class="other-btn" @click="closePage">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <span class="item-spacer" style="float: left">&nbsp;</span>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="dispList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
        >
          <!-- ロットNoヘッダー -->
          <template v-slot:[`header.lot`]>
            {{ $t("label.lbl_lot") + "／" + $t("label.lbl_serial") }}<br />
            {{
              $t("label.lbl_dueDate") +
              "／" +
              $t("label.lbl_lotSubkey1") +
              "／" +
              $t("label.lbl_lotSubkey2")
            }}<br />
          </template>

          <!-- 品番/品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div class="table-data-align-left">
              {{ item.productCnCd }}
            </div>
            <div class="table-data-align-left">
              {{ item.productName }}
            </div>
          </template>

          <!-- 資材品番/品名 -->
          <template v-slot:[`item.materialCd`]="{ item }">
            <div class="table-data-align-left">
              {{ item.materialCd }}
            </div>
            <div class="table-data-align-left">
              {{ item.materialName }}
            </div>
          </template>

          <!-- 付帯品番 -->
          <template v-slot:[`item.accessoryCd`]="{ item }">
            <div class="table-data-align-left">
              {{ item.accessoryCd }}
            </div>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lot`]="{ item }">
            <!-- ロットを表示 -->
            <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 350px" v-bind="attrs" v-on="on">
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <span>{{ item.lot }}<br />{{ item.dueDate }}</span>
              </v-tooltip>
            </div>
            <!-- serialが15文字以下だった場合ツールチップ無しで表示 -->
            <div v-else>
              {{ item.lot }}<br />
              {{ item.dueDate }}
            </div>
          </template>

          <!-- 予定数量 -->
          <template v-slot:[`item.scheduleQuantity`]="{ item }">
            <div
              class="table-data-align-right"
              v-if="
                (item.diffDiv == '01' || item.materialCd) &&
                item.situation == '済' &&
                !(searchParam.searchPlanningSection == '02' && item.materialKbn == '02')
              "
            >
              <a @click="openInventoryStatusDialog(item)" style="text-decoration: underline">
                <!-- 予定数量 -->
                {{ item.scheduleQuantity }}
              </a>
            </div>
            <div v-else class="table-data-align-right">
              <!-- 予定数量 -->
              {{ item.scheduleQuantity }}
            </div>
          </template>

          <!-- 引当結果 -->
          <template v-slot:[`item.situation`]="{ item }">
            <div v-if="item.diffDiv == '01' || item.materialCd">
              {{ item.situation }}
            </div>
          </template>
        </v-data-table>
        <!-- データテーブル用ページ遷移ボタン -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(dispList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>

      <!-- 在庫状況ダイアログ -->
      <v-dialog v-model="isShowinventoryStatusDialog" :max-width="1000" persistent scrollable>
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_inventoryStatus") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <!-- 品番/品名 -->
                <v-col>
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialogproductCnCd"
                    :label="$t('label.lbl_productCnCd')"
                    readonly
                  ></v-text-field>
                </v-col>

                <!-- 付帯品番 -->
                <v-col>
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialogaccessoryCd"
                    :label="$t('label.lbl_accessoryCd')"
                    readonly
                  ></v-text-field>
                </v-col>

                <!-- ロット -->
                <v-col>
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialoglot"
                    :label="$t('label.lbl_lot')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- S/N -->
                <v-col>
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialogSerial"
                    :label="$t('label.lbl_serial')"
                    readonly
                  ></v-text-field>
                </v-col>

                <!-- 期限日 -->
                <v-col>
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialogdueDate"
                    :label="$t('label.lbl_dueDate')"
                    readonly
                  ></v-text-field>
                </v-col>

                <!-- 付属キー1 -->
                <v-col>
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialoglotSubkey1"
                    :label="$t('label.lbl_lotSubkey1')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- 付属キー2 -->
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialoglotSubkey2"
                    :label="$t('label.lbl_lotSubkey2')"
                    readonly
                  ></v-text-field>
                </v-col>

                <!-- 資材品番/品名 -->
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="inventoryStatusDialogmaterialCdAndName"
                    :label="$t('label.lbl_materialCdAndName')"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-data-table
                id="inventoryStatusDialogList"
                :fixed-header="true"
                disable-filtering
                disable-sort
                :hide-default-footer="true"
                :headers="inventoryStatusDialogListHeader"
                :items="inventoryStatusDialogList"
                disable-pagination
                height="300px"
                class="overflow-hidden"
              >
                <!-- 予定数量 -->
                <template v-slot:[`item.scheduleQuantity`]="{ item }">
                  <span>
                    <!-- 予定数量 -->
                    {{ item.scheduleQuantity }}
                  </span>
                </template>
              </v-data-table>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- 閉じるボタン -->
              <v-btn class="other-btn" @click="closeInventoryStatusDialog">
                {{ $t("btn.btn_close") }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :screenFlag="ConfirmDialog.screenFlag"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
// import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
export default {
  name: appConfig.SCREEN_ID.P_TMP_907,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // 取引先
    supplier: "",
    // 作業日
    processingDate: "",
    // 日付ラベル
    processingDateLabel: "",
    // 伝票No
    voucherNo: "",
    // 出荷先
    // fromToName: "",
    // 輸送会社名
    shippingCompanyName: "",
    // 引当結果
    allocationResult: "",
    //前画面から受け取る値
    // 作業日(TO)
    processingDateTo: "",
    // 計画区分
    planningSection: "",
    // 作成日(FROM)
    arrCreatedDateFrom: "",
    // 伝票SID
    inOutListSub: "",
    // 計画・入出荷予定日
    inOutScheduleDate: "",
    // 伝票No
    inOutListNo: "",
    // 入出荷名称
    fromToName: "",
    // 路線業者名
    deliveryTraderName: "",
    // 遷移元画面名
    screenName: "",
    // 取引先SID
    supplierSelected: "",
    // ダイアログ関連
    // 閉じるボタン押下フラグ
    // closeDialogMessage: true,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      isOpen: false,
      redMessage: "",
      okAction: () => {},
    },
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
      screenFlag: false,
    },
    // 戻るボタンダイアログメッセージ
    backMessage: "",

    // ダイアログメッセージ
    closeMessage: "",
    // 検索条件
    searchParam: "",

    // データテーブル
    headerItems: [
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "15%",
        align: "left",
        class: "data-header-padding",
        sortable: false,
        cellClass: "",
      },
      // 資材品番/品名
      {
        text: i18n.tc("label.lbl_materialCdAndName"),
        value: "materialCd",
        width: "15%",
        align: "left",
        class: "data-header-padding",
        sortable: false,
        cellClass: "",
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_accessoryCd"),
        value: "accessoryCd",
        width: "15%",
        align: "left",
        class: "data-header-padding",
        sortable: false,
        cellClass: "",
      },
      // ロット
      {
        value: "lot",
        width: "15%",
        align: "left",
        class: "data-header-padding",
        sortable: false,
        cellClass: "",
      },
      // 予定数量
      {
        text: i18n.tc("label.lbl_scheduleQuantity"),
        value: "scheduleQuantity",
        width: "5%",
        align: "left",
        class: "data-header-padding",
        sortable: false,
        cellClass: "",
      },
      // 引当結果
      {
        text: i18n.tc("label.lbl_allocationStatus"),
        value: "situation",
        width: "15%",
        align: "center",
        class: "data-header-padding",
        sortable: false,
        cellClass: "data-align-center",
      },
    ],
    // データテーブル表示リスト
    dispList: [],

    // ページング
    u_10: false,
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    openMenu: false,
    // 総件数
    totalCount: 0,
    // ローディング
    loadingCounter: 0,
    // 在庫状況ダイアログ
    // ダイアログ表示フラグ
    isShowinventoryStatusDialog: false,
    // ダイアログヘッダー
    // 品番/品名
    inventoryStatusDialogproductCnCd: "",
    // 付帯品番
    inventoryStatusDialogaccessoryCd: "",
    // ロット
    inventoryStatusDialoglot: "",
    // S/N
    inventoryStatusDialogSerial: "",
    // 期限日
    inventoryStatusDialogdueDate: "",
    // 付属キー1
    inventoryStatusDialoglotSubkey1: "",
    // 付属キー2
    inventoryStatusDialoglotSubkey2: "",
    // 資材品番/品名
    inventoryStatusDialogmaterialCdAndName: "",

    // 在庫状況テーブルリスト
    inventoryStatusDialogListHeader: [
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 予定数量
      {
        text: i18n.tc("label.lbl_scheduleQuantity"),
        value: "scheduleQuantity",
        width: "10%",
        align: "right",
        sortable: false,
      },
    ],
    inventoryStatusDialogList: [],
  }),
  /**
   *  初期表示
   */
  created() {
    //一覧画面から受け取る値(実装)
    //取引先名
    this.supplier =
      this.$route.params.distributionProcessingPlanAllocationResultList.suppliersSelectedName;
    // 作業日
    this.processingDate =
      this.$route.params.distributionProcessingPlanAllocationResultList.inOutScheduleDate;
    //伝票No(検索条件)
    this.voucherNo = this.$route.params.distributionProcessingPlanAllocationResultList.inOutListNo;
    //資材引当結果
    this.allocationResult =
      this.$route.params.distributionProcessingPlanAllocationResultList.headerList.searchAllocationResult;
    //伝票SID
    this.inOutListSub =
      this.$route.params.distributionProcessingPlanAllocationResultList.inOutListSub;
    //入出荷先名称
    this.fromToName = this.$route.params.distributionProcessingPlanAllocationResultList.fromToName;
    //輸送会社名
    this.shippingCompanyName =
      this.$route.params.distributionProcessingPlanAllocationResultList.carrierCompName;
    ///路線業者名
    this.deliveryTraderName =
      this.$route.params.distributionProcessingPlanAllocationResultList.deliveryTraderName;
    //遷移元
    this.screenName = this.$route.params.distributionProcessingPlanAllocationResultList.screenName;
    //検索条件
    this.searchParam = this.$route.params.distributionProcessingPlanAllocationResultList.headerList;
    // 取引先(検索条件)
    this.supplierSelected =
      this.$route.params.distributionProcessingPlanAllocationResultList.headerList.searchSuppliersSelected;
    // 作業日ラベル
    this.processingDateLabel =
      this.$route.params.distributionProcessingPlanAllocationResultList.processingDateLabel;
    // データテーブル表示用データ取得
    this.getApiList();
  },
  methods: {
    /**
     * ページング処理
     */
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.showList();
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.showList();
    },

    /**
     * 引当結果確認画面：戻るボタン押下
     */

    closePage() {
      this.updateDialogMessage = true;

      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TMP_903,
        params: {
          backPage: "distributionProcessingPlanAllocationResultList",
          //検索条件
          headerList: this.searchParam,
        },
      });
    },

    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 加工引当結果取得API(GET)
     */
    getApiList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 伝票SID
      config.params.inOutListSid = this.inOutListSub;
      // 計画区分
      config.params.processingDiv = this.searchParam.searchPlanningSection;
      // 取引先SID
      config.params.clientSid = this.supplierSelected;
      // console.debug("getApiList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_ALLOCATION_RESULT, config)
          .then((response) => {
            // this.dispList.splice(0);
            // console.debug("getApiList() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              // レスポンスで取得した明細行データをリストに詰める
              jsonData.resIdv.dstProcessAllocationResultList.forEach((row) => {
                const lot = commonUtil.convertLotNo(row.lotNo);

                if (row.materialKbn == "02") {
                  list.push({
                    // 伝票明細SID
                    listSubSid: row.listSubSid,
                    // 商品標準SID
                    itemStandardSid: row.itemStandardSid,
                    // 資材区分
                    materialKbn: row.materialKbn,
                    // 品番
                    productCnCd: row.itemCd,
                    // 品名
                    productName: row.itemName,
                    // 資材品番
                    materialCd: "",
                    // 資材品名
                    materialName: "",
                    // 付帯品番
                    accessoryCd: row.itemSubCd,
                    // ロット、S/N
                    lot: lot.lot,
                    // 期限日、付属キー1、付属キー2
                    dueDate: lot.dueDate,
                    // 数量
                    scheduleQuantity: row.stock,
                    // 引当結果
                    situation: row.situation,
                    // 品番/品名ヘッダー
                    headerItemCd: row.itemCd + " (" + row.itemName + ")",
                    // 実態あり・なしフラグ
                    diffDiv: row.diffDiv,
                    // 在庫状況ダイアログ用
                    lotOriginal: lot.original,
                    lotOriginal2: row.lotNo,
                  });
                } else {
                  list.push({
                    // 伝票明細SID
                    listSubSid: row.listSubSid,
                    // 商品標準SID
                    itemStandardSid: row.itemStandardSid,
                    // 資材区分
                    materialKbn: row.materialKbn,
                    // 品番
                    productCnCd: "",
                    // 品名
                    productName: "",
                    // 資材品番
                    materialCd: row.itemCd,
                    // 資材品名
                    materialName: row.itemName,
                    // 付帯品番
                    accessoryCd: row.itemSubCd,
                    // ロット、S/N
                    lot: lot.lot,
                    // 期限日、付属キー1、付属キー2
                    dueDate: lot.dueDate,
                    // 数量
                    scheduleQuantity: row.stock,
                    // 引当結果
                    situation: row.situation,
                    // 品番/品名ヘッダー
                    headerItemCd: "",
                    // 実態あり・なしフラグ
                    diffDiv: row.diffDiv,
                    // 在庫状況ダイアログ用
                    lotOriginal: lot.original,
                    lotOriginal2: row.lotNo,
                  });
                }
              });
              // テーブルデータリストに明細行データを設定
              this.dispList = list;
              this.totalCount = this.dispList.length;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 在庫状況API(GET)
     */
    getStocksList(item) {
      this.inventoryStatusDialogList = [];
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 伝票明細
      config.params.listSubSid = item.listSubSid;
      // ロット
      config.params.lotNo = item.lotOriginal2;
      // 商品標準SID
      config.params.itemStandardSid = item.itemStandardSid;
      // 取引先SID
      config.params.clientSid = this.supplierSelected;
      // 品番
      if (item.materialKbn == "01") {
        config.params.itemCd = item.materialCd;
      } else {
        config.params.itemCd = item.productCnCd;
      }
      // 付属品番
      config.params.itemSubCd = item.accessoryCd;
      // 予定数量
      config.params.stock = item.scheduleQuantity;

      // console.debug("getStocksList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_PROCESS_INSTRUCTION_MAINTENANCE_STOCK, config)
          .then((response) => {
            // console.debug("getStocksList() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              // レスポンスで取得した明細行データをリストに詰める
              jsonData.resIdv.dstProcessInstructionStockList.forEach((row) => {
                list.push({
                  // 倉庫
                  warehouse: row.warehouseName,
                  // ロケー
                  location: row.locationNo,
                  // 予定数
                  scheduleQuantity: row.stock,
                });
              });
              // テーブルデータリストに明細行データを設定
              this.inventoryStatusDialogList = list;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 在庫状況モーダル：予定数リンクを押下する
     */
    openInventoryStatusDialog(item) {
      // モーダルを表示するフラグをON
      this.isShowinventoryStatusDialog = true;
      // 在庫状況一覧APIを呼び出す
      this.getStocksList(item);
      // 明細データを件数分回す
      for (var i = 0; i < this.dispList.length; i++) {
        // 明細データの資材区分が"02"かつ、選択行の伝票明細SIDと同じ場合
        if (
          this.dispList[i].materialKbn == "02" &&
          this.dispList[i].listSubSid == item.listSubSid
        ) {
          // ヘッダー：品番/品名
          this.inventoryStatusDialogproductCnCd =
            this.dispList[i].productCnCd + "(" + this.dispList[i].productName + ")";
          // ヘッダー：付帯品番
          this.inventoryStatusDialogaccessoryCd = this.dispList[i].accessoryCd;
        }
      }

      // ヘッダー：資材品番/品名
      if (item.materialKbn == "01") {
        this.inventoryStatusDialogmaterialCdAndName =
          item.materialCd + " (" + item.materialName + ")";
      } else {
        this.inventoryStatusDialogmaterialCdAndName = "";
      }
      // ヘッダー：ロット
      this.inventoryStatusDialoglot = item.lotOriginal.lot;
      // S/N
      this.inventoryStatusDialogSerial = item.lotOriginal.serial;
      // 期限日
      this.inventoryStatusDialogdueDate = item.lotOriginal.dueDate;
      // 付属キー1
      this.inventoryStatusDialoglotSubkey1 = item.lotOriginal.lotSubkey1;
      // 付属キー2
      this.inventoryStatusDialoglotSubkey2 = item.lotOriginal.lotSubkey2;
    },

    /**
     * 在庫状況モーダル：閉じるボタンを押下する
     */
    closeInventoryStatusDialog() {
      this.isShowinventoryStatusDialog = false;
    },

    /**
     * ページング処理
     */
    showList() {
      return this.dispList;
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss">
@import "../../css/style.css";

#inventoryStatusDialogList {
  tr:nth-child(odd) {
    background-color: #dde5f0;
    height: 2rem;
  }
  // テーブルデータ部分
  td {
    font-size: large;
  }
  th {
    font-size: large !important;
    color: black;
    background: #effad9;
    height: 2.5rem !important;
  }
}
</style>

<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.search-row {
  margin-bottom: 15px;
}

.title-ber.dialog {
  background-color: #dee6f3;
}

// 取引先
.textbox-search-supplier-readonly {
  width: 20rem;
}

// 作業日、出荷予定日
.textbox-date-readonly {
  width: 15rem;
}

// 出荷先
.textbox-shipping-to-readonly {
  width: 20rem;
}

// 輸送会社名
.textbox-shipping-company-name-readonly {
  width: 15rem;
}

.allocationstatus-ok-sing {
  margin-left: 0.01em;
}

.allocationstatus-ng-sing {
  color: red;
}

.allocationstatus-unregistered-sing {
  color: red;
}

// 明細テーブルのalign
.table-data-align-left {
  text-align: left;
}
.table-data-align-right {
  text-align: right;
}

.data-align-center > div {
  text-align: center;
}

.quantity-cell-padding {
  padding-right: 3em;
}
</style>
