<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_distributionInstructionsCancel" />
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-autocomplete-supplier first-search-item">
              <!-- 取引先-->
              <v-text-field
                dense
                id="get-supplier"
                v-model="suppliersSelectedName"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>
          </v-row>

          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--戻るボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" class="api-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div class="btn-area">
                <!--取消ボタン-->
                <v-btn color="primary" class="api-btn" @click="cancel">{{
                  $t("btn.btn_cancellation")
                }}</v-btn>
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!-- 表示件数切り替えボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          disable-filtering
          :hide-default-footer="true"
          height="600px"
          :sort-by.sync="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lotNo`]="{ header }">
            <span>
              <div>{{ header.text }}</div>
              <span>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </span>
          </template>
          <!-- 計画日 or 入荷予定日 or 出荷予定日 -->
          <template v-slot:[`header.processingDate`]="{}">
            <span v-text="processingDateLabel"></span>
          </template>

          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.cancelSubjectCheck`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.cancelSubjectCheck"
              :value="listCheckbox"
              @click="selectCheck(index)"
              style="transform: scale(2)"
              :disabled="item.checkFlg"
            />
          </template>

          <!-- 伝票No -->
          <template v-slot:[`item.voucherNo`]="{ item }">
            <span>
              <!-- 伝票Noを表示 -->
              {{ item.voucherNo }}
            </span>
          </template>

          <!-- 品番/品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productName.length > 10">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                    {{ item.productCnCd }}
                  </div>
                  <div class="text-overflow">
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.productCnCd }}</div>
              <div>{{ item.productName }}</div>
            </div>
          </template>

          <!-- 付属品番 -->
          <template v-slot:[`item.accessoryCd`]="{ item }">
            <span>
              <!-- 付属品番を表示 -->
              {{ item.accessoryCd }}
            </span>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lotNo`]="{ item }">
            <v-row style="text-align: left !important">
              <v-col
                v-if="item.lotNo.length > 15 || item.dueDate.length > 15"
                style="padding: 0 12px"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 290px" v-bind="attrs" v-on="on">
                      {{ item.lotNo }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </v-col>
              <v-col v-else style="padding: 0 12px">
                {{ item.lotNo }}<br />
                {{ item.dueDate }}
              </v-col>
            </v-row>
          </template>

          <!-- 予定数量 -->
          <template v-slot:[`item.scheduleQuantity`]="{ item }">
            <span class="number_data">
              <!-- 予定数量を表示 -->
              {{ item.scheduleQuantity }}
            </span>
          </template>

          <!-- 状況 -->
          <template v-slot:[`item.situation`]="{ item }">
            <span>
              <!-- 状況を表示 -->
              {{ item.situation }}
            </span>
          </template>

          <!-- 工程 -->
          <template v-slot:[`item.optHistList`]="{ item }">
            <v-container class="d-flex justify-space-around opt-hist-list-container">
              <template v-for="(optHist, index) in fixedOptHistOrder">
                <template v-if="inputList.some((obj) => obj.optHistList.includes(optHist.procCd))">
                  <!-- 工程を表示 -->
                  <div
                    class="flex-item"
                    v-text="item.optHistList.includes(optHist.procCd) ? optHist.procCdName : ''"
                    :key="index"
                  ></div>
                </template>
              </template>
              <template v-for="(optHist, index) in fixedOptHistOrder">
                <template v-if="!inputList.some((obj) => obj.optHistList.includes(optHist.procCd))">
                  <div class="flex-item" :key="index"></div>
                </template>
              </template>
            </v-container>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(inputList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- メッセージダイアログ-->

      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="deleteInstructions"
        :changeFlag="ConfirmDialog.changeFlag"
      />

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :dstSchListFlg="infoDialog.dstSchListFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />

      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ message }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.SCREEN_ID.P_TMP_909,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ページング

    // ページング
    u_100: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    itemsPerPageList: [100, 250, 500],

    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先プルダウン
    suppliersSelectedName: "",
    suppliersSelected: "",
    supplierList: [],
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    //初期表示時の件数インデックス
    inputListNumberIndex: -1,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    sortBy: "",

    // 前画面のページ名
    beforePage: "",
    //検索条件
    searchParam: "",
    // メニュー
    openMenu: null,
    // 警告ダイアログ
    alertDialog: false,
    message: "",
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
      dstSchListFlg: false,
      outsideClickNotCloseFlg: false,
    },
    // 総件数
    totalCount: 0,
    inputList: [],
    headerItems: [
      //取消対象
      {
        text: i18n.tc("label.lbl_cancelSubject"),
        value: "cancelSubjectCheck",
        width: "3%",
        align: "center",
        sortable: false,
      },
      //  計画日 or 入荷予定日 or 出荷予定日
      {
        text: i18n.tc("label.lbl_planDay"),
        value: "processingDate",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_voucherNo"),
        value: "voucherNo",
        width: "11%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "11%",
        align: "left",
        sortable: false,
      },
      // 付属品番
      {
        text: i18n.tc("label.lbl_accessoryCd"),
        value: "accessoryCd",
        width: "11%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lotNo",
        width: "16%",
        align: "left",
        sortable: false,
      },
      // 予定数量
      {
        text: i18n.tc("label.lbl_scheduleQuantity"),
        value: "scheduleQuantity",
        width: "6%",
        align: "left",
        sortable: false,
      },
      // 作業状況
      {
        text: i18n.tc("label.lbl_workSituation"),
        value: "situation",
        width: "6%",
        align: "left",
        sortable: false,
      },
      //工程
      {
        text: i18n.tc("label.lbl_opeHistRightArrow"),
        value: "optHistList",
        width: "29%",
        align: "left",
        sortable: false,
      },
    ],
    // 前画面より受け取る値
    backpage: "",
    processingDateTo: "",
    planningSection: "",
    allocationResult: "",
    arrCreatedDateFrom: "",
    inOutListSub: [],
    //作業日
    processingDate: "",
    processingDateLabel: "",
    //伝票No
    voucherNo: "",
    // 削除対象のインデックス
    deleteIndex: -1,
    // 削除対象リスト
    deleteList: [],

    // 作業指示内訳カラム固定値
    fixedOptHistOrder: [],
    // 工程情報リスト
    optHistList: [],

    // 入力チェック
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),

      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),

      // 数値チェック

      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),

      // 最大桁数チェック

      limitNumLength: (value) =>
        String(value).replaceAll(",", "").length < 10 || i18n.tc("check.chk_limitNumLength"),

      // 整数チェック

      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
    },
  }),

  methods: {
    init() {
      this.loadingCounter = 1;

      // 前画面の検索条件を一時保存(ヘッダー部分)
      // // 前画面の検索条件を保存(実装)
      this.searchParam = this.$route.params.distributionInstructionsCancelList.headerList;
      this.suppliersSelected =
        this.$route.params.distributionInstructionsCancelList.headerList.searchSuppliersSelected;
      this.suppliersSelectedName =
        this.$route.params.distributionInstructionsCancelList.suppliersSelectedName;
      this.backpage = this.$route.params.distributionInstructionsCancelList.screenName;
      this.processingDateLabel =
        this.$route.params.distributionInstructionsCancelList.processingDateLabel;
      this.checkList = this.$route.params.distributionInstructionsCancelList.inOutListSub;

      // 日付ラベル
      this.voucherNo = this.$route.params.voucherNo;
      // 完成指示取消参照APIの呼び出し
      this.getDistributionCancelInstructions();
    },

    /**
     * 一覧とページングネーションの幅調整処理
     */
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.showPage();
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.showPage();
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    /**
     * 一覧チェックボックス処理
     */
    selectCheck(index) {
      this.inputList[index].cancelSubjectCheck = true;
    },
    backPage() {
      this.updateDialogMessage = true;

      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    // 戻るボタンダイアログ：OK押下

    updateOk() {
      if (this.suppliersSelected != null) {
        this.updateDialogMessage = false;

        // 加工計画一覧に戻る
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TMP_903,

          params: {
            headerList: this.searchParam,
            backPage: this.backpage,
          },
        });
      }
    },

    // 戻るボタンダイアログ：NG押下

    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 完成指示取消参照AP
     */
    getDistributionCancelInstructions() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // リクエストパラメータを設定

      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STC_003;
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 計画区分
      body.reqIdv.processingDiv =
        this.$route.params.distributionInstructionsCancelList.headerList.searchPlanningSection;
      // 伝票SID。
      body.reqIdv.listSidList = this.checkList;
      // console.debug("getDistributionCancelInstructions() Body", body);
      console.log(body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.DST_PROCESS_INSTRUCTION_CANCEL, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDistributionCancelInstructions() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 加工完成指示内容用
              const list = [];
              jsonData.resIdv.instructCancelList.forEach((row) => {
                let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  processingDate: row.scheduleDate
                    ? dateTimeHelper.convertUTC2JST(row.scheduleDate).substr(0, 10)
                    : null, // 作業日
                  processingDateCal: row.scheduleDate
                    ? dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(row.scheduleDate))
                    : null, // 作業日
                  listSid: row.listSid, // 伝票SID
                  listSubSid: row.listSubSid, // 明細SID
                  voucherNo: row.listNo, // 伝票No
                  itemStandardSid: row.itemStandardSid, // 商品標準SID
                  productCnCd: row.itemCd, // 品番
                  productName: row.itemName, // 品名
                  accessoryCd: row.itemSubCd, // 付属品番
                  // ロット + S/N
                  lotNo: lotNoObj.lot,
                  //期限日 + 付属キー１ + 付属キー２
                  dueDate: lotNoObj.dueDate,
                  scheduleQuantity: row.scheduleQuantity, // 予定数量
                  optHistList: row.defalutProcList, // デフォルト工程情報リスト
                  optHistListDefalut: row.defalutProcList, // デフォルト工程情報リスト
                  createDataCheck: true, // チェックフラグ
                  checkFlg: false, // 削除対象チェックフラグ
                  firstProcCd: row.firstProcCd, // 初工程コード
                  parentItemDiv: row.parentItemDiv, // 親品番実態あり、なし区分
                  situation: row.situation, // 作業状況
                });
              });
              // 計画区分が加工の場合
              if (this.searchParam.searchPlanningSection == "03") {
                // 加工完成指示内容用リストを件数分回す
                list.forEach((row) => {
                  // デフォルト工程コードリストに500(出荷)が存在する場合、加工計画ルートでは最終工程が保管のため300(保管)に変換する
                  let shippingIndex = row.optHistListDefalut.findIndex((proc) => proc == 500);
                  row.optHistList[shippingIndex] = 300;
                  row.optHistListDefalut[shippingIndex] = 300;
                });

                // 工程コード変更用リスト
                const allProcList = [];

                jsonData.resIdv.allProcList.forEach((procData) => {
                  // 工程コードリストの工程コードが500の場合
                  if (procData.procCd == 500) {
                    // 工程コード変更用リストの工程コードに300,工程コード名に保管を追加
                    allProcList.push({
                      procCdName: "保管", // 工程コード名
                      procCd: 300, // 工程コード
                      procCdOrder: procData.procCdOrder, // 工程順
                    });
                  } else {
                    // 工程コード変更用リストに工程コードリストの値を追加
                    allProcList.push({
                      procCdName: procData.procCdName, // 工程コード名
                      procCd: procData.procCd, // 工程コード
                      procCdOrder: procData.procCdOrder, // 工程順
                    });
                  }
                });

                // 工程訳カラム固定値に工程コード変更用リストを入れる
                this.fixedOptHistOrder = allProcList;
              } else {
                // 計画区分が加工以外の場合
                // 工程コードリスト
                this.fixedOptHistOrder = jsonData.resIdv.allProcList;
              }

              // 加工指示作成画面の一覧を取得
              this.inputList = list;
              //総件数
              this.totalCount = this.inputList.length;
              // 取得した件数分取消対象のチェックを付ける
              this.inputList.forEach((data) => {
                // 状況が作業中の場合、削除対象チェックフラグをtrueにする
                if (data.situation == "作業中") {
                  data.checkFlg = true;
                  data.cancelSubjectCheck = false;
                } else {
                  data.cancelSubjectCheck = true;
                }
              });

              //初期表示時の件数インデックス
              this.inputListNumberIndex += this.inputList.length;
              resolve(list);
              // エラー時
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((resolve) => {
            this.infoDialog.message = resolve;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 完成指示取消登録API
     */
    registInstructionCancel() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TMP_909;
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 計画区分
      body.reqIdv.processingDiv =
        this.$route.params.distributionInstructionsCancelList.headerList.searchPlanningSection;
      // 明細Sid
      body.reqIdv.instructionCancelRegistList = this.deleteList.map((data) => data.listSubSid);
      // console.debug("registInstructionCancel() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.DST_PROCESS_INSTRUCTION_CANCEL_REGIST,
            body,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // console.debug("registInstructionCancel() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-TMP-909_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = false;
              // OK NGボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.dstSchListFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 取消ボタン押下処理
     */
    cancel() {
      // チェックした行
      const checkList = this.inputList.filter((data) => data.cancelSubjectCheck);

      // １つもチェックされていない場合、画面遷移できない
      if (!checkList.length) {
        // なければ警告ダイアログ表示
        this.alertDialog = true;
        this.message = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }
      //処理
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_cancellation"),
      ]);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    /**
     * 取消実行処理
     */
    deleteInstructions() {
      //削除対象行リスト
      const deleteIndexList = [];
      //チェック数
      var checkCnt = 0;
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      //チェック済の行を取得
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].cancelSubjectCheck == true && this.inputList[i].situation == "未") {
          deleteIndexList.push(i);
          this.deleteList.push(this.inputList[i]);
          this.deleteIndex = i;
          checkCnt += 1;
        }
      }
      // 完成指示取消登録API呼び出し
      this.registInstructionCancel();
      //チェック済の明細行を画面上から削除
      for (var t = 0; t < deleteIndexList.length; t++) {
        this.inputList.splice(deleteIndexList[t]);
      }
      //削除後の総件数
      this.totalCount = Number(totalNum) - Number(checkCnt);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * ページング処理
     */
    showPage() {
      return this.inputList;
    },
  },

  computed: {},

  watch: {
    // eslint-disable-next-line no-unused-vars

    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },

    dialog(val) {
      val || this.close();
    },

    inventoryDateCal(val) {
      this.inventoryDate = this.formatDate(val);
    },
  },

  created() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>

<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;

  font-size: large;
}

//検索条件：プルダウン
.search-autocomplete-supplier {
  width: 24rem;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}

// 数値項目右寄せ
span.number_data {
  display: block;
  text-align: right;
  padding-right: 30%;
}

.flex-item {
  flex: 1 1 0;
  padding: 5px;
  text-align: center;
  align-items: start;
  max-width: 100%;
  display: flex;
}

.opt-hist-list-container {
  gap: 20px;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
