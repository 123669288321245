import { render, staticRenderFns } from "./ShipDataImport.vue?vue&type=template&id=a2ab0292&scoped=true"
import script from "./ShipDataImport.vue?vue&type=script&lang=js"
export * from "./ShipDataImport.vue?vue&type=script&lang=js"
import style0 from "./ShipDataImport.vue?vue&type=style&index=0&id=a2ab0292&prod&lang=css"
import style1 from "./ShipDataImport.vue?vue&type=style&index=1&id=a2ab0292&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2ab0292",
  null
  
)

export default component.exports