<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_distributionHistory" />

      <v-container fluid>
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <!-- 取引先-->
            <div v-if="clientName.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      dense
                      readonly
                      v-model="clientName"
                      :label="$t('label.lbl_supplier')"
                      class="txt-single"
                      persistent-hint
                      margin-bottom="0"
                      pa-3
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ clientName }} </span>
              </v-tooltip>
            </div>
            <div v-else>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="clientName"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="date-style">
            <!-- 基準日 -->
            <v-text-field
              readonly
              dense
              v-model="arrAchieveDate"
              :label="$t('label.lbl_refernceDate')"
              class="txt-single"
            ></v-text-field>
          </div>
        </v-row>

        <!--ボタン領域-->
        <v-row>
          <div class="search-row-exeBtn">
            <!--戻るボタン領域-->
            <div class="btn-search-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn color="primary" class="other-btn" v-on:click="backbtn()">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
          </div>
        </v-row>

        <v-row>
          <div class="display-search-result-row">
            <!--検索結果表示-->
            <div class="text-label2" style="float: left">
              <span
                >{{ $t("label.lbl_totalDetail") }}：{{ totalCount
                }}{{ $t("label.lbl_number") }}</span
              >
            </div>
            <!-- 空白 -->
            <span class="item-historySpacer" style="float: left">&nbsp;</span>
          </div>
        </v-row>
      </v-container>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData_group"
          fixed-header
          :headers="headerItems"
          :items="inputListForTable"
          :items-per-page="-1"
          disable-filtering
          :hide-default-footer="true"
          group-by="groupByNo"
          height="630px"
        >
          <!-- ヘッダー部分 -->
          <!-- 予定数、実績数 -->
          <template v-slot:[`header.productQty`]>
            <div>
              {{ $t("label.lbl_scheduleQty") }}<br />
              {{ $t("label.lbl_achieveQty") }}
            </div>
          </template>

          <!-- ロットNoヘッダー -->
          <template v-slot:[`header.lot`]>
            {{ $t("label.lbl_lot") + "／" + $t("label.lbl_serial") }}<br />
            {{
              $t("label.lbl_dueDate") +
              "／" +
              $t("label.lbl_lotSubkey1") +
              "／" +
              $t("label.lbl_lotSubkey2")
            }}<br />
          </template>

          <!-- グルーピングヘッダー -->
          <template v-slot:[`group`]="{ group, items }">
            <template v-for="(groupingData, key) in items.filter((v) => v.parentDiv == '1')">
              <tr
                class="v-data-table-groupHeader"
                :key="groupingData.groupByNo + groupingData.itemCd + groupingData.qualityDiv + key"
              >
                <!-- グルーピングボタン -->
                <td class="header-mdi-plus" colspan="1">
                  <v-btn
                    v-if="
                      items.length !== 1 &&
                      groupingData.itemStandardSid === items[0].itemStandardSid
                    "
                    @click="customToggle(items)"
                    x-medium
                    icon
                  >
                    <v-icon v-if="groupingData.isOpen">mdi-minus</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                  </v-btn>
                </td>

                <!-- 伝票No -->
                <td>
                  <span>{{ groupingData.listNo }}</span>
                </td>

                <!-- 明細No -->
                <td>
                  <span>{{ groupingData.listSubNo }}</span>
                </td>

                <!-- 予定日 -->
                <td class="text-left">
                  <span>{{ groupingData.scheduleDate }}</span>
                </td>

                <!-- 予定数、実績数 -->
                <td class="text-right">
                  <div
                    v-if="
                      (groupingData.planRcv - groupingData.actualRcv == 0) |
                        (groupingData.actualRcv == '-')
                    "
                  >
                    <span>{{ groupingData.planRcv }}</span
                    ><br />
                    <span>{{ groupingData.actualRcv }}</span>
                  </div>
                  <div v-else class="diff2">
                    <span>{{ groupingData.planRcv }}</span
                    ><br />
                    <span>{{ groupingData.actualRcv }}</span>
                  </div>
                </td>

                <!-- 品番/品名 -->
                <td class="text-left">
                  <span>{{ groupingData.parentItemCd }}</span
                  ><br />
                  <span>{{ groupingData.parentItemName }}</span>
                </td>

                <!-- ロット -->
                <td>
                  <!-- ロットを表示 -->
                  <div v-if="groupingData.lot.length > 15 || groupingData.dueDate.length > 15">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="text-truncate"
                          style="max-width: 350px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ groupingData.lot }}<br />
                          {{ groupingData.dueDate }}
                        </div>
                      </template>
                      <span>{{ groupingData.lot }}<br />{{ groupingData.dueDate }}</span>
                    </v-tooltip>
                  </div>
                  <!-- serialが15文字以下だった場合ツールチップ無しで表示 -->
                  <div v-else>
                    {{ groupingData.lot }}<br />
                    {{ groupingData.dueDate }}
                  </div>
                </td>

                <!-- 品質区分 -->
                <td>
                  <template v-if="groupingData.qualityDiv == '良品'">
                    <span>{{ groupingData.qualityDiv }}</span>
                  </template>
                  <template v-else>
                    <a @click="openDefectiveDialog(groupingData)">
                      <span>{{ groupingData.qualityDiv }}</span>
                    </a>
                  </template>
                </td>

                <!-- ステータス -->
                <td class="text-left">
                  <template v-if="groupingData.testFlg == '0'">
                    <span>{{ groupingData.status }}</span>
                  </template>
                  <template v-else>
                    <a @click="openTestActualDialog(groupingData)">
                      <span>{{ groupingData.status }}</span>
                    </a>
                  </template>
                </td>

                <!-- 使用工程 -->
                <td class="text-center">
                  <span>{{ groupingData.procName }}</span>
                </td>

                <!-- 担当者 -->
                <td class="text-center">
                  <span>{{ groupingData.userName }}</span>
                </td>

                <!-- 実施日 -->
                <td class="text-center">
                  <span>{{ groupingData.implementationDate }}</span>
                </td>
              </tr>
            </template>

            <!-- 明細行 -->
            <template v-if="items[0].isOpen">
              <tr v-for="(item, key) in getDispData(items)" :key="item.listSubSid + key">
                <!-- トグルボタンのスペース -->
                <td>
                  <span></span>
                </td>

                <!-- 伝票No(表示しない) -->
                <td>
                  <span></span>
                </td>

                <!-- 明細No -->
                <td>
                  <span></span>
                </td>

                <!-- 予定日 -->
                <td>
                  <span>{{ item.scheduleDate }}</span>
                </td>

                <!-- 予実数 -->
                <td class="text-right">
                  <span>{{ item.planRcv }}</span
                  ><br />
                  <span>{{ item.actualRcv }}</span>
                </td>

                <!-- 品番/品名 -->
                <td>
                  <span>{{ item.itemCd }}</span
                  ><br />
                  <span>{{ item.itemName }}</span>
                </td>

                <!-- ロット -->
                <td>
                  <!-- ロットを表示 -->
                  <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="text-truncate"
                          style="max-width: 350px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.lot }}<br />
                          {{ item.dueDate }}
                        </div>
                      </template>
                      <span>{{ item.lot }}<br />{{ item.dueDate }}</span>
                    </v-tooltip>
                  </div>
                  <!-- serialが15文字以下だった場合ツールチップ無しで表示 -->
                  <div v-else>
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </td>

                <!-- 品質区分 -->
                <td>
                  <template v-if="item.qualityDiv == '良品'">
                    <span>{{ item.qualityDiv }}</span>
                  </template>
                  <template v-else>
                    <a @click="openDefectiveDialog(item)">
                      <span>{{ item.qualityDiv }}</span>
                    </a>
                  </template>
                </td>

                <!-- ステータス -->
                <td>
                  <span></span>
                </td>

                <!-- 工程 -->
                <td>
                  <span></span>
                </td>

                <!-- 担当者 -->
                <td>
                  <span></span>
                </td>

                <!-- 実施日 -->
                <td>
                  <span></span>
                </td>
              </tr>
            </template>
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <!-- メニュー -->
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 結果確認モーダル -->
      <v-dialog v-model="infoDialog.simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="infoDialog.simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :firstPageFlag="infoDialog.firstPageFlag"
        />
      </v-dialog>
      <!-- 不良品ダイアログ -->
      <ConfirmDialog
        :isShow.sync="confirmDialog.isShow"
        :title="confirmDialog.title"
        :message="confirmDialog.message"
        :cancelBtnFlg="confirmDialog.cancelBtnFlg"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="closeDefectiveDialog"
      >
      </ConfirmDialog>

      <!-- 検査実績ダイアログ -->
      <v-dialog v-model="testActualDialog.isShow" :max-width="1400" persistent>
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ testActualDialog.title }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeTestActualDialog">閉じる</v-btn>
          </v-card-actions>
          <v-card-text>
            <div class="table-space">
              <v-data-table
                id="testDetaillistData_group"
                fixed-header
                :headers="testActualDialog.testDetailHeader"
                :items="testActualDialog.testDetailList"
                :items-per-page="-1"
                :custom-sort="customSort"
                disable-filtering
                :hide-default-footer="true"
                group-by="groupByNo"
                height="630px"
              >
                <template v-slot:group="{ group, items }">
                  <tr>
                    <!-- グルーピングボタン -->
                    <td class="header-mdi-plus" colspan="1">
                      <v-btn @click="customToggle(items)" x-medium icon>
                        <v-icon v-if="items[0].isOpen">mdi-minus</v-icon>
                        <v-icon v-else>mdi-plus</v-icon>
                      </v-btn>
                    </td>

                    <td colspan="2">
                      <div class="title-row">
                        <div
                          v-for="(groupByNo, index) in group.split('@@@')"
                          :class="{
                            'first-element-style': index === 0,
                            'second-element-style': index === 1,
                          }"
                          :key="index"
                        >
                          {{ groupByNo }}
                        </div>
                      </div>
                    </td>

                    <td class="foundValue-border" colspan="1"></td>
                    <td colspan="1"></td>
                  </tr>
                  <!-- 明細行 -->
                  <template v-if="items[0].isOpen">
                    <tr
                      v-for="(item, key) in items"
                      :key="item.testItemDetailSid + item.yyyymmddHhmmss + key"
                    >
                      <!-- トグルボタンのスペース -->
                      <td>
                        <span></span>
                      </td>
                      <!-- 検査項目 -->
                      <td>{{ item.testItemName }}</td>

                      <!-- 検査詳細 -->
                      <td>{{ item.testDetailName }}</td>

                      <!-- 検査値 -->
                      <td class="foundValue-border">
                        <div
                          v-html="testInputEreaCrete(item.testPropertyCd, item.foundValue)"
                        ></div>
                      </td>

                      <!-- memo -->
                      <td>{{ item.badQualityComment }}</td>
                    </tr>
                  </template>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";

export default {
  name: appConfig.SCREEN_ID.P_TMP_911,
  components: {
    Loading,
    sideMenu,
    ConfirmDialog,
    SimpleDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // 検索ヘッダー
    searchParam: {
      suppliersSelected: "",
      suppliersSelectedName: "",
      arrAchieveDateTo: "",
    },
    clientName: "",
    arrAchieveDate: "",

    // 一覧用
    // ヘッダ
    headerItems: [],
    // 画面表示用ﾘｽﾄ
    inputList: [],
    totalCount: 0,

    // その他ダイアログ
    // メッセージダイアログ
    infoDialog: {
      simpleDialog: false,
      title: "",
      screenFlag: false,
      firstPageFlag: false,
    },

    // 不良品ダイアログ
    confirmDialog: {
      isShow: false,
      title: "不良詳細",
      message: "",
      cancelBtnFlg: false,
      screenFlag: true,
    },

    // 検査実績ダイアログ
    testActualDialog: {
      isShow: false,
      title: "検査実績",
      testDetailHeader: [],
      testDetailList: [],
    },

    // メニュー
    openMenu: null,
    // ローディング用
    loadingCounter: 0,
    // 工程
    procCd: "",
    procName: "",
    isOpen: false,

    // グルーピングデータ更新用
    // 不良親品番リスト
    parentBadList: [],
  }),
  methods: {
    // 初期化処理
    init() {
      this.headerItems = headerList;
      // 取引先名
      this.clientName = this.$route.params.clickItem.clientName;
      // 取引先SID
      this.clientSid = this.$route.params.clickItem.clientSid;
      // 検索ヘッダー
      this.searchParam = this.$route.params.searchParam;
      // 基準日
      this.arrAchieveDate = this.$route.params.searchParam.referenceDate;
      // 工程
      this.procCd = this.$route.params.procCd;
      // 工程名
      this.procName = this.$route.params.procName;
      // 一覧用データ取得
      this.getEntHistoryList();
    },

    // 一覧用
    // 一覧データ取得
    getEntHistoryList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // API接続用のconfigを取得
      const config = this.$httpClient.createGetApiRequestConfig();

      // パラメータ設定
      this.setParam(config);

      // 各工程の予定、実績を取得
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_PLAN_ACTUAL_DIFF_DETAIL, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              // データテーブル用
              if (jsonData.resIdv.planActualDiffDetailList.length == 0) {
                this.totalCount = 0;
                this.inputList = [];
                return;
              }
              this.inputList = jsonData.resIdv.planActualDiffDetailList;
              // 親品番が良品以外の場合はグルーピング行を分けるため一度不良品リストに登録しておく
              this.parentBadList = this.inputList.filter(
                (item) =>
                  item.parentDiv == "1" && item.qualityDiv != "良品" && item.qualityDiv != ""
              );
              this.totalCount = jsonData.resCom.resComCount;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.simpleDialog = true;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getEntHistoryList() Resolve", resolve);
            this.infoDialog.simpleDialog = true;
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    setParam(config) {
      config.params.yyyymmddHhmmss = dateTimeHelper.convertUTC(this.searchParam.referenceDate); // 基準日
      config.params.procCd = this.procCd; // 工程
      config.params.clientSid = this.clientSid; // 取引先

      if (this.searchParam.toSid) {
        config.params.toSid = this.searchParam.toSid; // 出荷先
      }
      if (this.searchParam.itemStandardSid) {
        config.params.itemStandardSid = this.searchParam.itemStandardSid; // 品番
      }
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.checkFlg = true;

      this.$router.push({
        name: appConfig.SCREEN_ID.P_TMP_910,
        params: {
          // 前画面の検索条件保持
          searchParam: this.searchParam,
        },
      });
    },

    // グルーピングボタン
    customToggle(items) {
      items.forEach((item) => {
        item.isOpen = !item.isOpen;
      });
    },

    // 明細行表示時の伝票データ排除
    getDispData(items) {
      return items.filter((item) => item.parentDiv != "1");
    },

    // 品質区分ダイアログ
    openDefectiveDialog(item) {
      this.confirmDialog.message = item.badQualityComment.join("\n");
      this.confirmDialog.isShow = true;
    },
    closeDefectiveDialog() {
      this.confirmDialog.isShow = false;
    },

    // 検査実績ダイアログ
    openTestActualDialog(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // API接続用のconfigを取得
      const config = this.$httpClient.createGetApiRequestConfig();

      // パラメータ設定
      this.setTestActualParam(config, item);

      // ヘッダーの設定
      this.testActualDialog.testDetailHeader = testTableHeaderList;

      // 各工程の予定、実績を取得
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DST_PLAN_ACTUAL_DIFF_TEST_DETAIL, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              // データテーブル用
              this.testActualDialog.testDetailList = jsonData.resIdv.testDetailList.flatMap(
                (data) => {
                  return data.testDetailList.flatMap((data2) => {
                    return {
                      listSid: data.listSid,
                      listGroup: data.listGroup,
                      titleName: data.titleName,
                      ...data2,
                      groupByNo: data.titleName + "@@@" + data2.yyyymmddHhmmss,
                    };
                  });
                }
              );
              this.testActualDialog.testDetailList.forEach((item, index) => {
                this.$set(this.testActualDialog.testDetailList, index, { ...item, isOpen: false });
              });
              this.testActualDialog.isShow = true;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.simpleDialog = true;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("openTestActualDialog() Resolve", resolve);
            this.infoDialog.simpleDialog = true;
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    closeTestActualDialog() {
      this.testActualDialog.isShow = false;
    },

    setTestActualParam(config, item) {
      config.params.procCd = this.procCd; // 工程
      config.params.clientSid = this.clientSid; // 取引先
      config.params.listSubSid = item.listSubSid; // 明細SID
      config.params.itemStandardSid = item.itemStandardSid; // 品番
    },

    // データのソートを「groupByNo」ではなく「yyyymmddHhmmss」、「titleName」、「autoNo」に変更
    // グループキーでソートしているのはグループキーでソートしないとグルーピングが崩れるため
    customSort(items) {
      items.sort((a, b) => {
        // 日付のソート
        const sortKeyDateTime1 = a.yyyymmddHhmmss;
        const sortKeyDateTime2 = b.yyyymmddHhmmss;
        if (sortKeyDateTime1 !== sortKeyDateTime2) {
          return sortKeyDateTime1.localeCompare(sortKeyDateTime2);
        }

        // 帳票名でのソート
        const sortKeyTitleName1 = a.titleName;
        const sortKeyTitleName2 = b.titleName;
        if (sortKeyDateTime1 !== sortKeyDateTime2) {
          return sortKeyTitleName1.localeCompare(sortKeyTitleName2);
        }

        // グループ化のキーでのソート
        const groupKey1 = a.groupByNo;
        const groupKey2 = b.groupByNo;
        if (groupKey1 !== groupKey2) {
          return groupKey1.localeCompare(groupKey2);
        }

        // autoNoでのソート
        const sortKeyAutoNo1 = Number(a.autoNo);
        const sortKeyAutoNo2 = Number(b.autoNo);
        return sortKeyAutoNo1 - sortKeyAutoNo2;
      });
      return items;
    },

    testInputEreaCrete(testPropertyCd, foundValue) {
      let inputArea = "";
      switch (testPropertyCd) {
        // テキスト
        case appConfig.TEST_PROPERTY_CD.TEXT:
          inputArea = `<div>${foundValue}</div>`;
          break;
        // 合否、テキスト
        case appConfig.TEST_PROPERTY_CD.PASS_FAIL_TEXT: {
          let [pass, text] = foundValue.split("@");
          let passHtml;
          if (pass === "01") {
            passHtml = `<div class="pass"><span class="circle">合</span></div><div class="fail"><span class="no-circle">否</span></div>`;
          } else {
            passHtml = `<div class="pass"><span class="no-circle">合</span></div><div class="fail"><span class="circle">否</span></div>`;
          }
          inputArea = `<div class="test-input-area"><div class="pass-fail">${passHtml}</div><div class="found-value"><span>${text}</span></div></div>`;
          break;
        }
        // 日付
        case appConfig.TEST_PROPERTY_CD.DATE:
          inputArea = `<div>${foundValue}</div>`;
          break;
        // 整数
        case appConfig.TEST_PROPERTY_CD.INTEGER:
          inputArea = `<div class="found-value-int">${foundValue}</div>`;
          break;
        // 合否、整数
        case appConfig.TEST_PROPERTY_CD.PASS_FAIL_INTEGER: {
          let [pass, text] = foundValue.split("@");
          let passHtml;
          if (pass === "01") {
            passHtml = `<div class="pass"><span class="circle">合</span></div><div class="fail"><span class="no-circle">否</span></div>`;
          } else {
            passHtml = `<div class="pass"><span class="no-circle">合</span></div><div class="fail"><span class="circle">否</span></div>`;
          }
          inputArea = `<div class="test-input-area"><div class="pass-fail">${passHtml}</div><div class="found-value-int"><span>${text}</span></div></div>`;
          break;
        }
        // 合否、整数、小数点第1
        case appConfig.TEST_PROPERTY_CD.PASS_FAIL_INTEGER_DECIMAL_POINT1: {
          let [pass, text] = foundValue.split("@");
          let passHtml;
          if (pass === "01") {
            passHtml = `<div class="pass"><span class="circle">合</span></div><div class="fail"><span class="no-circle">否</span></div>`;
          } else {
            passHtml = `<div class="pass"><span class="no-circle">合</span></div><div class="fail"><span class="circle">否</span></div>`;
          }
          inputArea = `<div class="test-input-area"><div class="pass-fail">${passHtml}</div><div class="found-value-int"><span>${text}</span></div></div>`;
          break;
        }
        // 合否、整数、小数点第2
        case appConfig.TEST_PROPERTY_CD.PASS_FAIL_INTEGER_DECIMAL_POINT2: {
          let [pass, text] = foundValue.split("@");
          let passHtml;
          if (pass === "01") {
            passHtml = `<div class="pass"><span class="circle">合</span></div><div class="fail"><span class="no-circle">否</span></div>`;
          } else {
            passHtml = `<div class="pass"><span class="no-circle">合</span></div><div class="fail"><span class="circle">否</span></div>`;
          }
          inputArea = `<div class="test-input-area"><div class="pass-fail">${passHtml}</div><div class="found-value-int"><span>${text}</span></div></div>`;
          break;
        }
        // レ点
        case appConfig.TEST_PROPERTY_CD.SCORE:
          if (foundValue === "01") {
            inputArea = `<input type=checkbox checked onclick="return false;" style="transform: scale(2)"/>`;
          } else {
            inputArea = `<input type=checkbox onclick="return false;" style="transform: scale(2)"/>`;
          }
          break;
        // 合否
        case appConfig.TEST_PROPERTY_CD.PASS_FAIL: {
          let passHtml;
          if (foundValue === "01") {
            passHtml = `<div class="pass"><span class="circle">合</span></div><div class="fail"><span class="no-circle">否</span></div>`;
          } else {
            passHtml = `<div class="pass"><span class="no-circle">合</span></div><div class="fail"><span class="circle">否</span></div>`;
          }
          inputArea = `<div class="test-input-area"><div class="pass-fail">${passHtml}</div><div class="found-value"></div></div>`;
          break;
        }
        // 電子印
        case appConfig.TEST_PROPERTY_CD.ELECTRONIC_SEAL:
          inputArea = `<div class="test-input-area">${foundValue}</div>`;
          break;
      }
      return inputArea;
    },
  },
  computed: {
    inputListForTable: function () {
      return this.inputList.map((item) => {
        let groupByNo;
        // 不良親品番の場合は良品の親品番とは別のグルーピングをして表示するため
        // 不良親品番リストの中身と同じ明細行(集約される行)の場合はグルーピングNoを変更する
        if (
          this.parentBadList.some(
            (parentBadItem) =>
              parentBadItem.parentItemCd == item.itemCd &&
              item.qualityDiv != "良品" &&
              item.qualityDiv != ""
          )
        ) {
          groupByNo = item.listSubSid + "bad";
        } else {
          groupByNo = item.listSubSid;
        }
        const lotObj = commonUtil.convertLotNo(item.lotNo);
        const inputItem = {
          groupByNo: groupByNo,
          procName: this.procName,
          isOpen: false,
          lot: lotObj.lot,
          dueDate: lotObj.dueDate,
          ...item,
        };
        return inputItem;
      });
    },
  },
  watch: {},
  mounted() {
    this.init();
  },
};
const headerList = [
  // グルーピングボタン用
  {
    value: "groupingButton",
    width: "1%",
    align: "left",
    sortable: false,
    groupable: false,
  },
  // 伝票No
  {
    text: i18n.tc("label.lbl_billingSlipNo"),
    value: "slipNo",
    width: "10%",
    align: "left",
    sortable: false,
  },
  // 明細No
  {
    text: i18n.tc("label.lbl_detailNo"),
    value: "slipSubNo",
    width: "5%",
    align: "left",
    sortable: false,
  },
  // 予定日
  {
    text: i18n.tc("label.lbl_schDate"),
    value: "scheduleDate",
    width: "7%",
    align: "left",
    sortable: false,
  },
  // 予定数実績数
  {
    value: "productQty",
    width: "5%",
    align: "right",
    sortable: false,
  },
  // 品番/品名
  {
    text: i18n.tc("label.lbl_productCnCd"),
    value: "product",
    width: "15%",
    align: "left",
    sortable: false,
  },
  // ロット
  {
    value: "lot",
    width: "15%",
    align: "left",
    sortable: false,
  },
  // 品質区分
  {
    text: i18n.tc("label.lbl_qualityDiv"),
    value: "qualityDiv",
    width: "5%",
    align: "left",
    sortable: false,
  },
  // ステータス
  {
    text: i18n.tc("label.lbl_status"),
    value: "status",
    width: "5%",
    align: "left",
    sortable: false,
  },
  // 工程
  {
    text: i18n.tc("label.lbl_opeHist"),
    value: "procName",
    width: "7%",
    align: "center",
    sortable: false,
  },
  // 担当者
  {
    text: i18n.tc("label.lbl_PIC"),
    value: "userName",
    width: "7%",
    align: "center",
    sortable: false,
  },
  // 実施日
  {
    text: i18n.tc("label.lbl_implementationDate"),
    value: "implementationDate",
    width: "7%",
    align: "center",
    sortable: false,
  },
];

const testTableHeaderList = [
  {
    value: "groupingButton",
    width: "5%",
    sortable: false,
    groupable: false,
  },
  {
    text: "検査項目",
    value: "testItemName",
    width: "10%",
    sortable: false,
    groupable: false,
  },
  {
    text: "検査詳細",
    value: "testDetailName",
    width: "45%",
    sortable: false,
    groupable: false,
  },
  {
    text: "検査値",
    value: "foundValue",
    width: "20%",
    sortable: false,
    groupable: false,
  },
  {
    text: "memo",
    value: "badQualityComment",
    width: "20%",
    sortable: false,
    groupable: false,
  },
];
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
// 入荷履歴空白
.item-historySpacer {
  margin-left: 200px;
  margin-right: 250px;
}
.text-label2 {
  margin-top: 20px;
}

// ラベル
.text-historylabel {
  margin-top: 0px;
}
.link {
  text-decoration: none;
}

.isD {
  background-color: $color-difference !important;
  //background-color: $color-error !important;
}
.diff2 {
  background-color: #d09cfc;
}

.header-mdi-plus {
  width: 20px;
  overflow: hidden;
  white-space: nowrap;
}

.table-space {
  height: 630px;
}

//データテーブル
#listData_group {
  &.v-data-table--fixed-header ::v-deep {
    tr {
      background-color: #ffffff;
      height: 2rem;
    }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;
      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    // グループヘッダー
    .v-data-table-groupHeader {
      background-color: #dde5f0;
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #dde5f0;
    }
  }
}
#testDetaillistData_group {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    // th::after {
    //   content: "*" !important;
    //   color: red;
    // }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    .first-element-style {
      width: 60%;
    }
    .second-element-style {
      width: auto;
    }

    td {
      font-size: large;
    }

    td.foundValue-border {
      border-right: dotted 2px #cccccc;
      border-left: dotted 2px #cccccc;
    }

    // 検査値データ表示部
    .title-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .circle {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 29px;
      border: solid 3px red;
    }
    .no-circle {
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 29px;
    }
    .test-input-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .test-input-area > .pass-fail {
      flex: 0.8;
      display: flex;
      align-items: center;
    }
    .test-input-area > .pass-fail > .pass,
    .fail {
      display: flex;
      flex: 1;
    }

    .test-input-area > .found-value {
      flex: 1;
    }

    .test-input-area > .found-value-int {
      text-align: right;
      flex: 1;
    }

    .found-value-int {
      text-align: right;
    }
  }
}
</style>
